import React from 'react';
import Button from '@mui/material/Button';

function CustomButton(props) {
  return <Button variant="contained" color="primary" {...props} />;
}

export const RefButton = React.forwardRef(({ props, children }, ref) => {
  return (
    <Button {...props} variant="contained" children={children} ref={ref} />
  );
});

export default CustomButton;
