/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Hidden } from '@mui/material';
import { Button } from '@Components/Button';
import { spinnerStore } from '@Store/spinnerStore';
import classes from './dashboard.module.scss';
import { RegisterEq } from './RegisterEq';
import { TablePaginationGrid } from './CardsGrid';
import { dashboardQuery } from './dashboard.query';
import scrollToTop from 'utils/scrollToTop';
import getUserAccount, {
  getUserSFDetails,
  setUserSFDetails,
} from 'utils/getUserAccount';
import { authService } from '@Services/auth';
import { DistributorDashboard } from './DistributorDashboard';
import NoResultsFound from '@Components/NoRecordsFound/NoResultsFound';
import { CardAccordion } from './RotaryAccordion';
import SearchIcon from './../../assets/dashboardICons/Search_light.svg';
import xIcon from './../../assets/dashboardICons/xIcon.svg';
import { useLocation } from 'react-router-dom';

function Dashboard() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [showDistrbutorModal, setShowDistrbutorModal] = useState(false);
  const handleOpen = () => {
    const userSf = getUserSFDetails();
    if (userSf.userRole === 'Distributor' && !userSf.distributorNumber) {
      setShowDistrbutorModal(true);
    } else if (userSf.userRole === 'Installer' && !userSf.installerNumber) {
      setShowDistrbutorModal(true);
    } else {
      setOpen(true);
    }
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { setSpinner, isActive } = spinnerStore();
  const [searchText, setSearchText] = useState('');
  const [iconFlag, setIconFlag] = useState(false);
  const [pageValue, setPageValue] = useState(1);
  const [payload, setPayload] = useState('');
  const { data, status, isSuccess, isLoading } = dashboardQuery.useProducts(
    page + 1,
    rowsPerPage,
    payload,
    pageValue,
  );

  // const isLoading = status === 'loading';

  const fetchResults = (event) => {
    const encodedSearchQuery = encodeURIComponent(searchText);
    event.preventDefault();
    setPayload(encodedSearchQuery);
    setIconFlag(true);
  };

  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);

  const handleChangePage = (event, newPage) => {
    if (payload !== '') {
      if (pageValue < data.totalPages) {
        setPageValue(pageValue + 1);
      }
    }

    if (payload !== '') {
      if (newPage < data?.pageNumber - 1) {
        // if (pageValue < data?.pageNumber - 1) {
        setPageValue(pageValue - 1);
      }
    }
    setPage(newPage);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    scrollToTop();
  };
  const handleClick = () => {
    setSearchText('');
    setPayload('');
    setIconFlag(false);
    setPageValue(1);
  };

  const onSucessPopUp = () => {
    setShowDistrbutorModal(false);
    const userAccount = getUserAccount();
    authService.getUserDetails(userAccount.email).then(({ data }) => {
      setUserSFDetails(data.response);
    });
  };

  const handlePopup = (value) => {
    setShowDistrbutorModal(value);
  };
  useEffect(() => {
    if (location.search !== '') {
      setOpen(true);
    }
  }, [!location.search !== '']);
  useEffect(() => {
    if (!searchText) {
      setIconFlag(false);
      return setPayload('');
    }
  }, [searchText]);
  const user = getUserSFDetails();

  // useEffect(() => {
  //   const unloadCallback = (event) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //     return '';
  //     localStorage.clear();
  //     sessionStorage.clear();
  //   };
  //   window.onbeforeunload = null;
  //   window.addEventListener('beforeunload', unloadCallback);
  //   return () =>
  //     window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  // }, []);
  return (
    <>
      <div className={classes.dashboardNavbarSection}>
        <h2>My Equipment</h2>
        <Hidden mdDown>
          <div className={classes.searchForm}>
            <form class={classes.nosubmit} onSubmit={fetchResults}>
              <div className={classes.searchContainer}>
                <input
                  className={classes.searchInput}
                  type="text"
                  placeholder="Search by Serial Number, Product Name, Installer "
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                {(iconFlag === false || searchText === '') && (
                  <img
                    src={SearchIcon}
                    alt="SearchIcon"
                    className={classes.searchIcon}
                    onClick={fetchResults}
                  />
                )}
                {iconFlag === true && searchText !== '' && (
                  <img
                    src={xIcon}
                    alt="xIcon"
                    className={classes.searchIcon}
                    onClick={handleClick}
                  />
                )}
              </div>
            </form>
          </div>
        </Hidden>
        <div className={classes.registerNew}>
          <Hidden mdUp>
            {user.userRole === 'CSR' ? (
              <Button type="submit" onClick={handleOpen}>
                Register or Transfer
              </Button>
            ) : (
              <Button type="submit" onClick={handleOpen}>
                Register
              </Button>
            )}
          </Hidden>
          <Hidden mdDown>
            {user.userRole === 'CSR' ? (
              <Button type="submit" onClick={handleOpen}>
                Register or Transfer
              </Button>
            ) : (
              <Button type="submit" onClick={handleOpen}>
                Register New Equipment
              </Button>
            )}
          </Hidden>
        </div>
        <Hidden mdUp>
          <div className={classes.searchForm}>
            <form class={classes.nosubmit} onSubmit={fetchResults}>
              <div className={classes.searchContainer}>
                <input
                  className={classes.searchInput}
                  type="text"
                  placeholder="Search by Product Name, Serial Number, Installer "
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                />
                {(iconFlag === false || searchText === '') && (
                  <img
                    src={SearchIcon}
                    alt="SearchIcon"
                    className={classes.searchIcon}
                    onClick={fetchResults}
                  />
                )}
                {iconFlag === true && searchText !== '' && (
                  <img
                    src={xIcon}
                    alt="xIcon"
                    className={classes.searchIcon}
                    onClick={handleClick}
                  />
                )}
              </div>
            </form>
          </div>
        </Hidden>
      </div>
      <>
        {isSuccess && data && (
          <>
            <div className={classes.middleContainer}>
              {data?.equipments.map((eq) => (
                <>
                  <CardAccordion equipment={eq} key={eq?.equipmentNumber} />
                </>
              ))}
            </div>
            {data?.totalRecords > 2 && (
              <div className={classes.pagination}>
                <TablePaginationGrid
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={data?.pageNumber - 1}
                  rowsPerPage={data?.pageSize}
                  count={data?.totalRecords}
                />
              </div>
            )}
          </>
        )}
        {isSuccess && !data && <NoResultsFound handleClick={handleOpen} />}
      </>
      <div className={classes.RegisterEquipt_Modal}>
        {open && <RegisterEq open={open} setOpen={setOpen} />}
      </div>
      {showDistrbutorModal && (
        <DistributorDashboard
          open={showDistrbutorModal}
          setOpen={handlePopup}
          onSucessPopUp={onSucessPopUp}
        />
      )}
    </>
  );
}

export default Dashboard;
