import { useFormik } from 'formik';
import * as yup from 'yup';

const formFeilds = [
  {
    label: 'Equipment Owner Email Address',
    id: 'endUserEmail',
    name: 'endUserEmail',
    required: true,
    elementName: 'input',
    type: 'text',
    placeholder: 'Equipment Owner Email Address',
  },
];

const validations = yup.object().shape({
  endUserEmail: yup
    .string()
    .email('Please Enter a Valid Email')
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
      'Please Enter a Valid Email',
    )
    .required('Email Required!'),
});

const initialFormValues = {
  endUserEmail: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { formFeilds, validations, useFormHook };
