import React, { useEffect } from 'react';
import useClickHandlers from '../hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { instalationQuery } from '../Installation.query';
import classes from './RegConfirmation.module.scss';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';

function RegConfirmation() {
  const {
    setStepNext,
    setStepPrev,
    locationDetails,
    installationType,
    selectedEquipmentId,
    equipmentTypes,
  } = installationStore();
  const { id } = useParams();
  const email = getUserSFDetails().email;
  const { data } = dashboardQuery.useProduct(email, id, true);
  const { setSpinner, isActive } = spinnerStore();
  const {
    mutate,
    data: productdata,
    isLoading: isloadingdata,
    status,
    error,
  } = instalationQuery.useMutationRegisterEq();
  const eq = equipmentTypes?.find(
    (ele) => String(ele.id) === String(selectedEquipmentId),
  );
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () =>
      window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  }, []);
  useEffect(() => {
    if (status === 'success') {
      document.querySelector('#cancelRegister').style.display = 'none';
      setStepNext();
    }
  }, [status]);
  const endUserDetails = installationStore((state) => state.endUserDetails);
  let user = getUserSFDetails();
  if (endUserDetails?.id) {
    user = endUserDetails;
  }

  const handleNextClick = () => {
    let payload = {
      equipmentId: data.equipmentId,
      userEmail: user.email,
      distributorId: data.distributorId,
      userID: user.id,
      equipmentNumber: id,
      accountId: locationDetails?.accountId,
      registrationSource: 'WebApp',
      InstallerTypeID: +selectedEquipmentId,
      installerLocationId: locationDetails?.id,
      RegisteredBy: getUserSFDetails()?.id,
      RegisteredByEmail: getUserSFDetails()?.email,
    };

    if (String(selectedEquipmentId) === '1') {
      payload = {
        ...payload,
        // InstallerId: installationType?.installerId,
        installerFirstName: installationType?.fullName,
        installerLastName: installationType?.lastName || '',
        InstallerPhone: installationType?.contactNumber,
        installerEmail: installationType?.email,
      };
    }

    if (String(selectedEquipmentId) === '2') {
      payload = {
        ...payload,
        InstallerId: installationType?.installerId,
        installerNumber: installationType?.installerNumber,
        installerName: installationType?.name,
      };
    }

    if (String(selectedEquipmentId) === '3') {
      payload = {
        ...payload,
        // InstallerId: installationType?.installerId,
        InstallerPhone: installationType?.installerNumber,
        installerEmail: installationType?.installerEmail,
        companyName: installationType?.companyName,
      };
    }

    mutate(payload);
  };
  const handlePrevClick = () => setStepPrev();

  const genrateStringByEqId = (idx) => {
    idx = String(idx);
    let string = '';
    if (idx === '1') {
      const { fullName, lastName } = installationType;
      string = fullName;
      string += lastName ? ', ' + lastName : '';
    }
    if (idx === '2') {
      const { name } = installationType;
      string = name;
    }

    if (idx === '3') {
      const { companyName } = installationType;
      string = companyName;
    }

    return string;
  };
  const getAddress = () => {
    const { address1, address2, city, state, country, zip } = locationDetails;
    return [address1, address2, city, state, country, zip]
      .filter((ele) => !!ele)
      .join(', ');
  };
  const Installer = genrateStringByEqId(selectedEquipmentId);
  const addressStr = getAddress();
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  useClickHandlers(handleNextClick, handlePrevClick);
  return (
    <div className={classes.regConfirmationPage}>
      <div className={classes.regConfirmationCont}>
        <div className={classes.equipDetails}>
          <h2>Equipment Details</h2>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Product Name</p>
            <p className={classes.equipSubHeadDetail}>{data?.productName}</p>
          </div>
          <div className={classes.SerailsNumber}>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Serial Number</p>
              <p className={classes.equipSubHeadDetail}>
                {data?.equipmentNumber}
              </p>
            </div>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Product Code</p>
              <p className={classes.equipSubHeadDetail}>{data?.productCode}</p>
            </div>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer Type</p>
            <p className={classes.equipSubHeadDetail}>{eq?.installerType}</p>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer</p>
            <p className={classes.equipSubHeadDetail}>{Installer}</p>
          </div>
        </div>
        <div className={classes.locationDetails}>
          <h2>Location & Contact Details</h2>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Business Name</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.name}
            </p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Address of the Equipment</p>
            <p className={classes.equipSubHeadDetail}>{addressStr}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Email</p>
            <p className={classes.equipSubHeadDetail}>{user.email || 'N/A'}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Phone</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.phoneNumber || 'N/A'}
            </p>
          </div>
        </div>
      </div>
      {error?.response?.data?.message === 'Product Already Registered!!' && (
        <div>
          <Modal
            open={
              error?.response?.data?.message ===
                'Product Already Registered!!' &&
              error?.response?.data?.statusCode === 400
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.ModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalTitle}
              >
                {error?.response?.data?.message}
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={classes.paraGraph}
              >
                <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: '600' }}>
                    {error?.response?.data?.message}
                  </span>
                  , please click on back button to Register New Product
                </div>
              </Typography>

              <div className={classes.verifyButton}>
                <Link to="/Dashboard">
                  <Button variant="contained" color="primary">
                    Back
                  </Button>
                </Link>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default RegConfirmation;
