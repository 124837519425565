import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { spinnerStore } from '@Store/spinnerStore';
import classes from './spinner.module.scss';

const Spinner = () => {
  const { isActive, hideSpinner } = spinnerStore();
  const location = useLocation();
  React.useEffect(() => {
    hideSpinner();
  }, [location]);
  if (!isActive) return null;
  return (
    <>
      <div className={classes.overlay}></div>
      <div className={classes.wrapper}>
        <div className={classes['sk-circle']}>
          <div
            className={classNames(classes['sk-circle1'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle2'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle3'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle4'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle5'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle6'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle7'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle8'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle9'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle10'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle11'], classes['sk-child'])}
          ></div>
          <div
            className={classNames(classes['sk-circle12'], classes['sk-child'])}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Spinner;
