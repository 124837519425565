import React from 'react';
import classes from './Footer.module.scss';
import classNames from 'classnames';

function FooterSection() {
  const getCurrentYear = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return currentYear;
  };
  const [year, setYear] = React.useState(getCurrentYear());
  return (
    <footer>
      <div className={classNames(classes.footerSection, 'footer-wrapper')}>
        <div className={classes.footerCont}>
          <p>
            © 1999 - {year}. Rotary Lift | Powered by{' '}
            <a
              href="https://vsgdover.com/?__hstc=59739570.a56eacb93a3ec1db9ce792bec55e98a2.1679641100984.1680678415939.1680709646415.6&__hssc=59739570.1.1680709646415&__hsfp=32358173"
              target="_blank"
              rel="noreferrer"
            >
              Vehicle Service Group℠
            </a>{' '}
            | All Rights Reserved. Unless otherwise indicated, Vehicle Service
            Group and all other trademarks are property of Dover Corporation and
            its affiliates.
          </p>
          {/* <p className={classes.footerLinks}>
            Privacy Policy | Cookie Usage | Terms of Service | Do Not Sell
          </p> */}
          <p className={classes.footerLinks}>
            <a
              href="https://vsgdover.com/privacy-policy/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.1.1680683821278&__hsfp=32358173"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href="https://vsgdover.com/cookie/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.2.1680683821278&__hsfp=32358173"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Usage
            </a>{' '}
            |{' '}
            <a
              href="https://vsgdover.com/terms-of-service/?__hstc=59739570.e91bdf985d82e8a9f6d88db2be16a7d1.1680683821277.1680683821277.1680683821277.1&__hssc=59739570.3.1680683821278&__hsfp=32358173"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Services
            </a>{' '}
            |{' '}
            <a
              href="http://vsgdover.com/do-not-sell"
              target="_blank"
              rel="noreferrer"
            >
              Do Not Sell
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
