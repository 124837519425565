import { createContext, useRef } from 'react';

const ButtonRefsContext = createContext({
  nextButtonRef: null,
  prevButtonRef: null,
});

export const useButtonRefs = () => {
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  return { nextButtonRef, prevButtonRef };
};

export default ButtonRefsContext;
