import { useEffect } from 'react';
import { installationStore } from '@Store/installationStore';

import { shallow } from 'zustand/shallow';

function useButtonsState(touched, isValid, isExistingStep) {
  const { setNextButton, isNextDisable } = installationStore(
    (state) => ({
      setNextButton: state.setNextButton,
      isNextDisable: state.isNextDisable,
    }),
    shallow,
  );
  useEffect(() => {
    const isTouched = Object.keys(touched).length !== 0 || isExistingStep;
    if (isTouched && isValid && isNextDisable) {
      setNextButton(false);
    }
    if (!isNextDisable && !(isTouched && isValid)) {
      setNextButton(true);
    }
  }, [touched, isValid]);
}

export default useButtonsState;
