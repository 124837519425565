import { useFormik } from 'formik';
import * as yup from 'yup';

const formFeilds = [
  {
    label: 'Business Name (starts with)',
    id: 'locationName',
    name: 'locationName',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Business Name',
  },
  {
    label: 'Business ZIP Code',
    id: 'locationZip',
    name: 'locationZip',
    required: true,
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Business ZIP Code',
    maxLength: '7',
  },
];

const validations = yup.object().shape(
  {
    locationZip: yup
      .string()
      .ensure()
      .max(7, 'Zip code should contain 7 digits')

      .matches(/^[a-zA-Z0-9 ]*$/, 'Please Enter a Valid Zip')
      .when('locationName', {
        is: '',
        then: yup.string().required('Location ZIP Code required!'),
      }),
  },
  [['locationName', 'locationZip']],
);

const initialFormValues = {
  locationName: '',
  locationZip: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { formFeilds, validations, useFormHook };
