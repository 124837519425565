/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { installationStore } from '@Store/installationStore';
import classes from './SelfInstallation.module.scss';
import { MappedInput } from '@Components/Input';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import useButtonsState from 'features/Installation/hooks/useButtonsState';
import { InputController } from '@Components/Input/MappedInput';
import {
  formFeilds,
  useFormHook,
  f,
} from './SelfInstallation.form.initializer';
import { shallow } from 'zustand/shallow';
import { getUserSFDetails } from 'utils/getUserAccount';

function SelfInstallation() {
  const user = getUserSFDetails();
  const { setInstallationType, setStepPrev, setNextButton } =
    installationStore();
  const installationType = installationStore(
    (state) => state.installationType,
    shallow,
  );
  const isExistingStep = installationType?.id === 1;
  const [numberFlag, setNumberFlag] = useState(false);
  const onSubmit = (values) => {
    setInstallationType({ ...values, id: 1 }, true);
  };
  useEffect(() => {
    if (!form.values.fullName) {
      form.setFieldValue('zip', '');
    }
  }, []);
  const form = useFormHook(onSubmit, isExistingStep && installationType);
  const handleNextClick = () => form.submitForm();

  const handlePrevClick = () => {
    setStepPrev();
    if (user.userRole === 'CSR') {
      setInstallationType({});
    }
  };

  const EndUserDetails = localStorage.getItem('EndUserDetail');

  const EndUserData = JSON.parse(EndUserDetails);

  useEffect(() => {
    if (
      user.userRole === 'Basic User' &&
      Object.keys(installationType).length === 0
    ) {
      const phnoneNumberFormat = user.phoneNumber
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      setNumberFlag(user.phoneNumber.length === 10 ? false : true);
      form.setFieldValue('fullName', user.firstName);
      form.setFieldValue('lastName', user.lastName);
      form.setFieldValue('contactNumber', phnoneNumberFormat);
      form.setFieldValue('email', user.email);
      form.setTouched({}, true);
    }
    if (user.userRole === 'CSR' && Object.keys(installationType).length === 0) {
      // if (user.userRole === 'CSR') {
      const phnoneNumberFormatEndser =
        EndUserData[0] &&
        EndUserData[0]?.phoneNumber
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4,})/, '($1) $2-$3');
      setNumberFlag(EndUserData[0]?.phoneNumber.length === 10 ? false : true);

      form.setFieldValue('fullName', EndUserData[0].firstName);
      form.setFieldValue('lastName', EndUserData[0].lastName);
      form.setFieldValue('contactNumber', phnoneNumberFormatEndser);
      form.setFieldValue('email', EndUserData[0].email);
      form.setTouched({}, true);
    }
  }, [user.userRole, installationType?.zip === undefined]);

  useEffect(() => {
    if (form.isValid === false) {
      form.setTouched({}, true);
    }
    if (form.isValid === true && form.dirty === true) {
      setNextButton(false);
    }
  }, [form.values.email === user.email, form.isValid, form.dirty]);

  useEffect(() => {
    if (form.errors.contactNumber && form.touched.contactNumber) {
      setNumberFlag(true);
    }
  }, []);
  const { touched, isValid } = form;
  useButtonsState(touched, isValid, isExistingStep);
  useClickHandlers(handleNextClick, handlePrevClick);

  return (
    <div className={classes.selfInstallationCont}>
      <div className={classes.instalMiddleRightCont}>
        <div className={classes.installRightCont}>
          <div className={classes.Register_rightContainer}>
            <form onSubmit={form.handleSubmit}>
              {/* <MappedInput fields={formFeilds} form={form} /> */}
              {/* <MappedInput fields={f['rowOne']} form={form} /> */}
              <InputController el={f['rowOne'][0]} form={form} />
              {/* <MappedInput fields={f['rowTwo']} form={form} /> */}
              <InputController el={f['rowTwo'][0]} form={form} />

              <InputController
                el={f['rowThree'][0]}
                form={form}
                className={
                  form.errors.contactNumber &&
                  form.touched.contactNumber !== true &&
                  numberFlag === true &&
                  classes.fieldRow
                }
              />
              {numberFlag === true &&
                form.errors.contactNumber &&
                form.touched.contactNumber !== true && (
                  <p
                    style={{
                      textAlign: 'left',
                      color: '#c51e1e',
                      marginTop: '-14px',
                      marginBottom: '8px',
                    }}
                  >
                    Please enter valid 10 digits Contact Number
                  </p>
                )}

              <InputController el={f['rowFive'][0]} form={form} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfInstallation;
