import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    // Tell MUI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontSize: '16px',
  },
  button: {
    palette: {
      background: {
        default: '#222222',
        primary: '#1A68AD',
      },
      text: {
        primary: '#ffffff',
      },
    },
  },
  root: {
    fontSize: '1rem',
  },
});
