/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import classes from './UserLogoff.module.scss';
import { Button } from '@Components/Button';
import getUserAccount from 'utils/getUserAccount';

function UserLogoff() {
  const { instance } = useMsal();
  const userAccount = getUserAccount();
  const policyURL = localStorage.getItem('policyURL');
  useEffect(() => {
    const url = location.host;
    instance.logoutRedirect({
      idTokenHint: userAccount.idToken,
      authority: policyURL,
    });
    localStorage.clear();
    sessionStorage.clear();
    instance.browserStorage.clear();

    // const url =
    //   'https://cxportal.b2clogin.com/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGNA_COMBINED/oauth2/v2.0/logout?post_logout_redirect_uri=msauth.com.rotary.rotaryreg://auth';
    // instance.logoutRedirect(url);
  }, []);

  return (
    <div className={classes.logoutContainer}>
      <div className={classes.textStyles}>You have been logged out.</div>
      {/* <Link to="/register-equipement-login">
        <Button className={classes.buttonStyles}></Button>
      </Link> */}
      <div className={classes.submit}>
        <Button
          type="submit"
          variant="contained"
          className={classes.buttonStyles}
        >
          Return to login
        </Button>
      </div>
    </div>
  );
}

export default UserLogoff;
