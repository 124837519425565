import { useQuery, useMutation } from '@tanstack/react-query';
import { installationService } from '@Services/installation';
import { toast } from 'react-toastify';

export default function useLazyQuery(key, fn, options = {}) {
  const query = useQuery(key, fn, {
    ...options,
    enabled: false,
  });

  return [query.refetch, query];
}

const useInstallerTypes = () => {
  return useQuery(
    ['installerTypes'],
    async () => {
      const { data } = await installationService.getInstallerTypes();
      return data?.response;
    },
    { cacheTime: 10000 },
  );
};

const useInstallersByZip = (zipCode) => {
  return useLazyQuery(
    ['installersByZip', zipCode],
    async () => {
      const { data } = await installationService.getInstallerByZip(zipCode);
      return data?.response;
    },
    { cacheTime: 5000 },
  );
};

const useInstallersByNameOrZip = (payload) => {
  return useLazyQuery(['installersByZip', payload], async () => {
    const { data } = await installationService.getLocationsByZipOrName(
      payload?.userId,
      payload?.name,
      payload?.zip,
    );
    return data;
  });
};

const useMutationSaveLocation = () => {
  return useMutation(installationService.saveLocation, {
    onSuccess: ({ data }) => {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: ({ response }) => {
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};

const useMutationCreateUser = () => {
  return useMutation(installationService.createUser, {
    onSuccess: ({ data, response }) => {
      localStorage.setItem('createUser', data?.message);
      toast.success(data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: ({ response }) => {
      localStorage.setItem('createUser', response);
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};

const useMutationUpdateSaveLocation = () => {
  return useMutation(installationService.updateLocation, {
    onSuccess: ({ data }) => {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: ({ response }) => {
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};
const useMutationRegisterEq = () => {
  const { data, isLoading, isError, status, mutate, error, failureReason } =
    useMutation(installationService.registerUserEquipment, {
      onError: ({ response }) => {
        localStorage.setItem('productData', response?.data?.message);
        return response.data;
      },
    });
  return { mutate, data, isLoading, isError, status, failureReason, error };
};

const useCountriesList = (createUserFlag) => {
  return useQuery(
    ['countries'],
    async () => {
      const { data } = await installationService.getCountries(createUserFlag);
      return data?.response;
    },
    { cacheTime: 10000 },
  );
};
const useStateCode = (countryID, createUserFlag) => {
  return useQuery(
    ['stateCode', countryID],
    async () => {
      const { data } = await installationService.getStates(
        countryID,
        createUserFlag,
      );
      return data?.response;
    },
    { cacheTime: 10000, enabled: countryID ? true : false },
  );
};
const useGetUserDetails = (email) => {
  return useLazyQuery(
    ['email', email],

    async () => {
      const { data } = await installationService.getEndUser(email);
      return data?.response;
    },

    { cacheTime: 5000 },
  );
};

const useMutationtexaActivate = () => {
  return useMutation(installationService.texaActivate, {
    onSuccess: ({ data, response }) => {
      localStorage.setItem('activatedCode', data?.response?.activatedCode);
      // toast.success(data?.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    },
    onError: ({ response }) => {
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};
export const instalationQuery = {
  useInstallerTypes,
  useInstallersByZip,
  useInstallersByNameOrZip,
  useMutationSaveLocation,
  useMutationUpdateSaveLocation,
  useMutationRegisterEq,
  useCountriesList,
  useStateCode,
  useGetUserDetails,
  useMutationCreateUser,
  useMutationtexaActivate,
};
