import React from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import RenderRow from './RenderRow';
import classes from './SearchResults.module.scss';
const defaultKeys = ['name', 'address', 'city', 'zip'];
// const defaultKeys = ['name', 'city', 'zip'];
const defaultkey = ['endUserEmail'];
const SearchResults = ({
  items,
  title,
  handleChange,
  keys = defaultKeys.length > 1 ? defaultKeys : defaultkey,
  selectRowId = null,
}) => {
  if (!handleChange) {
    handleChange = () => {};
  }

  return (
    <>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.searchLocationResult}>
        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleChange}
              value={selectRowId}
            >
              {items &&
                items.map((data) => (
                  <RenderRow key={data?.id} data={data} keys={keys} />
                ))}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default React.memo(SearchResults);
