import { api } from '@Services/config';

const login = async (data) => api.post(`User/ValidateUser`, data);

const logout = async () => api.post(`User/SaveUser`);
const logoutApi = async (email) => api.get(`/User/LogoutUser?email=${email}`);
const forgetPwd = async (data) => api.post(`User/ForgotPassword`, data);
const getUserDetails = async (email) =>
  api.get(`/User/GetUserDetails?email=${email}`);
const loginIDP = async (email) => api.get(`/User/GetPolicy?email=${email}`);
const create = (data) => api.post(`User/SaveUser`, data);
const updateDistributor = (data) => api.post(`User/ValidateDistributor`, data);
const updateInstaller = (data) => api.post(`User/ValidateInstaller`, data);
const activate = async (data) => api.post(`User/ActivateUser`, data);
const authService = {
  create,
  login,
  forgetPwd,
  logout,
  logoutApi,
  activate,
  getUserDetails,
  updateDistributor,
  updateInstaller,
  loginIDP,
};

export default authService;
