/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import classes from './Input.module.scss';
import Tooltip from '@mui/material/Tooltip';
import IconArtwork from '../../../assets/dashboardICons/Icon Artwork.svg';
import { installationStore } from '@Store/installationStore';
function InputField({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  onBlur,
  errors,
  touched,
  required,
  className: externalclassName = '',
  maxLength = '',
}) {
  const isInputError = touched && errors;
  const styles = {
    formControl: classes.formControl,
    error: 'input-error',
    border: '2px solid red',
  };

  const cx = classNames.bind(styles);

  const classList = cx({
    formControl: true,
    [classes.inputError]: isInputError,
    [externalclassName]: true,
  });

  /* Poojitha
  below function is used to trim the value in input field
  */
  const handleInputValidation = (e) => {
    const value = e.target.value;
    if (value && value.trim() === '') {
      e.target.value = '';
    }
    onChange(e);
  };
  const { steps } = installationStore();

  const { currentStep } = steps || {};
  return (
    <div
      className={classNames([classes.formBlock, 'form-control-block', name])}
    >
      {label && (
        <label className={classes.formLabel} htmlFor="input-field">
          {label} {required && <span className={classes.formLabelReq}>*</span>}
          {/* {type === 'email' && currentStep === 2 && (
            <span>
              <Tooltip
                title="Equipment owner email address"
                enterTouchDelay={0}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'white',
                      color: 'gray',
                      fontSize: '12px',
                      boxShadow:
                        ' rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',

                      '& .MuiTooltip-arrow': {
                        color: 'black',
                      },
                    },
                  },
                }}
              >
                <img src={IconArtwork} alt="IconArtwork" />
              </Tooltip>
            </span>
          )} */}
        </label>
      )}

      <input
        type={type}
        value={value}
        name={name}
        className={classList}
        placeholder={placeholder}
        onChange={handleInputValidation}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      {isInputError && (
        <span className={classNames([classes.errorMsg, 'input-error-mgs'])}>
          {errors}
        </span>
      )}
    </div>
  );
}

export default React.memo(InputField);
