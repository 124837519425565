import React from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledRadio = (props) => (
  <Radio
    color="default"
    sx={{
      color: '#000000',
      '&.Mui-checked': {
        color: '#CE1F1F !important',
        '&MuiTypography-root': {
          color: '#CE1F1F',
        },
      },
      '&+span': {
        textTransform: 'initial',
      },
      '&.Mui-checked + span': {
        color: '#CE1F1F !important',
      },
    }}
    {...props}
  />
);

export const StyledRadioControl = ({ value, label }) => (
  <FormControlLabel value={value} control={<StyledRadio />} label={label} />
);

export default StyledRadio;
