import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';
import { Hidden } from '@mui/material';
import WrappedInstallationMapper from './WrappedInstallationMapper';
import rightArrow from '../../assets/rightArrow.svg';
import HandleNav from './HandleNav/HandleNav';
import classes from './Installation.module.scss';
import { installationStore } from '@Store/installationStore';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { useParams } from 'react-router-dom';
import ButtonRefsContext, {
  useButtonRefs,
} from './hooks/NavigationButtons.context';
import InstallationSteps from './InstallationSteps/InstallationSteps';
import ProductHeader from './ProductHeader';
import { getUserSFDetails } from 'utils/getUserAccount';
import { RequestForTransfer } from '../dahboard/RotaryAccordion/RequestForTransfer';

function Installation() {
  const { id } = useParams();
  const email = getUserSFDetails().email;
  const { data } = dashboardQuery.useProduct(email, id, true);
  const { nextButtonRef, prevButtonRef } = useButtonRefs();
  const { setEmptyState } = installationStore();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const user = getUserSFDetails();
  useEffect(() => {
    return () => setEmptyState();
  }, []);
  const statusId = localStorage.getItem('statusId');
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
  return (
    <div className={classes.InstallationContainer}>
      <div className={classes.TopNavigationCont}>
        <div className={classes.TopNavigationSection}>
          <div>
            {!valueFalg ? (
              <h2>New Equipment Registration</h2>
            ) : (
              <h2>Equipment Information</h2>
            )}
          </div>
          <div className={classes.buttonStyles}>
            <Hidden mdDown>
              <div className={classes.CancelRegister} id="cancelRegister">
                {!valueFalg ? (
                  <Link to="/Dashboard">
                    <Button
                      type="submit"
                      variant="outlined"
                      className={classes.cancelRegistration}
                    >
                      Cancel Registration
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type="submit"
                    variant="outlined"
                    className={classes.cancelRegistration}
                    onClick={handleOpen}
                  >
                    Request for Transfer
                  </Button>
                )}
              </div>
            </Hidden>
            {
              <div className={classes.RegisterEquipt_Modal}>
                {open && (
                  <RequestForTransfer open={open} setOpen={setOpen} eq={data} />
                )}
              </div>
            }
          </div>
        </div>
        <div className={classes.SubNavigationSection}>
          <Link to="/Dashboard" className={classes.ActiveLink}>
            My Equipment
          </Link>
          <img src={rightArrow} alt="rightArrow" />
          {!valueFalg ? (
            <Link to="/">New Equipment Registration</Link>
          ) : (
            <span className={classes.navTextStyles}>Equipment Information</span>
          )}
          <Hidden mdUp>
            <div className={classes.CancelRegister} id="cancelRegister">
              {!valueFalg ? (
                <Link to="/Dashboard">
                  <Button type="submit" variant="outlined">
                    Cancel
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  variant="outlined"
                  className={classes.cancelRegistration}
                  onClick={handleOpen}
                >
                  Request for Transfer
                </Button>
              )}
            </div>
          </Hidden>
        </div>
      </div>
      <ButtonRefsContext.Provider value={{ nextButtonRef, prevButtonRef }}>
        <div className={classes.InstallationMiddleSection}>
          <div className={classes.InstallationCardCont}>
            {!valueFalg ? (
              <div className={classes.headingSection}>
                <ProductHeader data={data} />
              </div>
            ) : (
              <div></div>
            )}
            <InstallationSteps />
            <div className={classes.installationMiddleCont}>
              <WrappedInstallationMapper />
            </div>
          </div>
        </div>

        <div className={classes.nextButton}>
          <HandleNav />
        </div>
      </ButtonRefsContext.Provider>
    </div>
  );
}

export default Installation;
