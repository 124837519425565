import React from 'react';
import FindLocationDetails from './FindLocation';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledRadioControl } from '@Components/Radio';
import classes from './LocationDetails.module.scss';
import NewLocation from './NewLocation';
import { installationStore } from '@Store/installationStore';
function LocationDetails() {
  const { selectlocations, setSelectlocations } = installationStore();
  const handleChange = ({ target }) => {
    setSelectlocations(target?.value || 'findLocation');
  };
  return (
    <div className={classes.LocationDetailCont}>
      <h2>
        Please provide the location details of where the equipment got installed
      </h2>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleChange}
        value={selectlocations}
      >
        <div className={classes.radioBtnsWrapper}>
          <StyledRadioControl value="findLocation" label="Find Location" />
          <StyledRadioControl value="newLocation" label="Enter New Location" />
        </div>
      </RadioGroup>

      {selectlocations === 'findLocation' ? (
        <FindLocationDetails />
      ) : (
        <NewLocation />
      )}
    </div>
  );
}

export default LocationDetails;
