/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import classes from './MenuBar.module.scss';
import { getUserSFDetails } from 'utils/getUserAccount';
import xIcon from '../../../assets/dashboardICons/xIcon.svg';
import { authStore } from '@Store/authStore';
import { useNavigate } from 'react-router-dom';
import { authService } from '@Services/auth';
import Document from './../../../assets/HelpPDF/Doc.pdf';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
// interface Props {
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
//   window?: () => Window;
// }
const drawerWidth = 240;

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userObj, setUserObj] = useState({});
  const [delAccountFlag, setDelAccountFlag] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const sUserObj = () => {
    setTimeout(() => {
      const userObjx = getUserSFDetails() || {};
      setUserObj(userObjx);
      if (!userObjx.firstName) {
        sUserObj();
      }
    }, 200);
  };

  useEffect(() => {
    let userObjx = getUserSFDetails() || {};
    if (!userObjx.firstName) {
      sUserObj();
    } else {
      setUserObj(userObjx);
    }
  }, []);

  const { logout } = authStore();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleCloseUserMenu = () => {
    logout();
    authService.logoutApi(getUserSFDetails().email);
    setAnchorElUser(null);
    setTimeout(() => {
      navigate('/user-logoff');
    }, 300);
  };

  const handleDeleteAccount = () => {
    setDelAccountFlag(true);
  };

  const drawer = (
    <Box>
      <div className={classes.containerStyles}>
        <Typography
          variant="h6"
          sx={{ my: 2 }}
          className={classes.userNameStyles}
        >
          <div style={{ display: 'flex' }}>{userObj?.firstName}</div>
          {userObj.distributorName ? (
            <div className={classes.companyNameStyles}>
              {userObj.distributorName}
            </div>
          ) : userObj.installerName ? (
            <div className={classes.companyNameStyles}>
              {userObj.installerName}
            </div>
          ) : null}
        </Typography>
        <img
          src={xIcon}
          alt="xIcon"
          onClick={handleDrawerToggle}
          style={{ paddingRight: '15px' }}
        />
      </div>
      <Divider />
      <List>
        <ListItem disablePadding></ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              className={classes.menuItemStyles}
              onClick={handleDeleteAccount}
            >
              Delete Account
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <a href={Document} target="_blank" rel="noreferrer">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText className={classes.menuItemStyles}>
                Help
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </a>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              onClick={handleCloseUserMenu}
              className={classes.menuItemStyles}
            >
              Logout
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" style={{ boxShadow: 'none', zIndex: '0' }}>
          <Toolbar className={classes.menuIcon}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon style={{ fontSize: 29 }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                left: 'unset',
                right: '0 !important',
                transition: 'unset !important',
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      <div>
        <Modal
          open={delAccountFlag}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.ModalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={classes.modalTitle}
            >
              Request for Delete Account
            </Typography>

            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              className={classes.paraGraph}
            >
              <div style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '10px' }}>
                  {' '}
                  Please note before deleting your account:
                </div>
                <div style={{ marginBottom: '10px' }}>
                  The account deletion process usually takes 1-2 business days
                  to complete.
                </div>

                <div style={{ marginBottom: '10px' }}>
                  Once your account has been successfully deleted, you will
                  receive a confirmation email from our Customer Support Team.{' '}
                </div>

                <div style={{ marginBottom: '10px' }}>
                  Please be aware that deleting your account will result in the
                  permanent loss of all your account-related information,
                  including your profile details and any associated data. This
                  action cannot be undone.
                </div>
              </div>
            </Typography>

            <div className={classes.verifyButton}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDelAccountFlag(false)}
                  className={classes.buttonStyles}
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.buttonStyles}>
                <a href="mailto:email@example.com?cc=secondemail@example.com, anotheremail@example.com, &bcc=lastemail@example.com&subject=Hello Customer Support Team&body=Some body text here">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '200px' }}
                  >
                    Delete Account
                  </Button>
                </a>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
