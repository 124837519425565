import axios from 'axios';

export const createAxiosInstance = (config) => {
  /**
   * Axios config
   * @see {@link https://github.com/axios/axios#config-defaults}
   * @type {Object}
   */
  const axiosConfig = {
    /**
     * Base url
     * @type {string}
     */
    baseURL: config.API_URL,
    /**
     * Base headers
     * @type {Object}
     */
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '488babbf4754454fa6525641b4f5a537', //Prod
      // 'Ocp-Apim-Subscription-Key': '79671a62294e4e3aac6aa3e3f0a07fc9', //UAT
      // 'Ocp-Apim-Subscription-Key': 'eb7b1d5dfc014d1d8ce44fb49b03b89e', //dev
    },
  };

  const api = axios.create(axiosConfig);

  const setApiHeader = (key, value) => {
    api.defaults.headers.common[key] = value;
  };

  return { api, setApiHeader };
};
