import { useMsal } from '@azure/msal-react';
function Login() {
  //   const { mutateLogin, loginError } = useAuthQuery();
  const { instance } = useMsal();
  instance.loginRedirect({
    authority:
      // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
      // 'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
      'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgpr',
    // loginHint: `${form.values.username}`,
  });
  //   const onSubmit = (values) => {
  //     showSpinner();
  //     mutateLogin({ ...values });
  //   };
  //   useEffect(() => {
  //     form.resetForm();
  //     hideSpinner();
  //   }, [loginError]);
  //   const form = useFormHook(onSubmit);
  //   return (
  //     <>
  //       <div className={classes.LoginPageContainer}>
  //         <div className={classes.rightContainer}>
  //           <form onSubmit={form.handleSubmit}>
  //             <h2>Welcome Back</h2>
  //             <MappedInput fields={formFeilds} form={form} />
  //             <div className={classes.forgetPwd}>
  //               <Link to="/forgot-password" className={classes.blueClr}>
  //                 Forgot Password?
  //               </Link>
  //             </div>
  //             <div className={classes.submit}>
  //               <Button type="submit">LOGIN</Button>
  //             </div>
  //             <div className={classes.newUser}>
  //               <span>New user? </span>
  //               <Link to="/register" className={classes.registerHere}>
  //                 Register here
  //               </Link>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //     </>
  //   );
}

export default Login;
