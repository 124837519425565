/* eslint-disable no-console */
const getUserAccount = () => {
  const accountRaw = localStorage.getItem('userAccount');
  if (!accountRaw) return null;
  const account = JSON.parse(accountRaw);
  const {
    idTokenClaims: { name, exp, otherMails },
    idToken,
  } = account;
  return {
    idToken,
    name,
    exp,
    email: otherMails[0],
  };
};

export default getUserAccount;

const setUserSFDetails = (data) => {
  localStorage.setItem('userSfDetails', JSON.stringify(data));
};
// user account details will get below function
const getUserSFDetails = () => {
  const userSf = localStorage.getItem('userSfDetails');
  if (!userSf) return null;
  return JSON.parse(userSf);
};

const isEndUser = (role) => {
  if (!role) return false;
  return ['Distributor', 'Installer', 'CSR'].includes(role);
};

export { setUserSFDetails, getUserSFDetails, isEndUser };
