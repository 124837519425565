import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const authStore = create(
  persist(
    (set, get) => ({
      user: null,
      isLoggedIn: false,
      setUser: (data) => {
        set({ user: data });
      },

      login: (data) => {
        set({ user: data, isLoggedIn: true });
      },

      logout: () => {
        set({ user: null, isLoggedIn: false });
      },
    }),
    { name: 'vsg-user', storage: createJSONStorage(() => localStorage) },
  ),
);
