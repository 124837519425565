import React from 'react';
import classes from './InstallationContainer.module.scss';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { useParams } from 'react-router-dom';
import { installationStore } from '@Store/installationStore';
import InstallerTypes from '../InstallerTypes';
import lineDivider from '../../../assets/lineDivider.svg';
import SelfInstallation from '../InstallationType/SelfInstallation';
import RotaryAuthorizedInstaller from '../InstallationType/RAI';
import NonRotaryAuthorizedInstaller from '../InstallationType/Non-RAI';
import { shallow } from 'zustand/shallow';
import { getUserSFDetails } from 'utils/getUserAccount';

const GetComponent = ({ id }) => {
  const idx = +id;
  if (idx === 1) return <SelfInstallation />;
  if (idx === 2) return <RotaryAuthorizedInstaller />;
  if (idx === 3) return <NonRotaryAuthorizedInstaller />;
  return null;
};

const WrapperGetComponent = React.memo(GetComponent);

function InstallationContainer() {
  const { id } = useParams();
  const email = getUserSFDetails().email;
  const { data } = dashboardQuery.useProduct(email, id, true);
  const selectedEquipmentId = installationStore(
    (state) => state.selectedEquipmentId,
    shallow,
  );

  return (
    <div className={classes.InstallationContainer}>
      <div className={classes.instalMiddleLeftCont}>
        <InstallerTypes />
      </div>

      <div className={classes.lineDivider}>
        <img src={lineDivider} alt="LineDivider" />
      </div>

      {selectedEquipmentId === null && (
        <div className={classes.instalMiddleRightCont}>
          <div className={classes.installRightCont}>
            <h2>Please select an option to view/fill the details</h2>
          </div>
        </div>
      )}
      {selectedEquipmentId !== null && (
        <WrapperGetComponent id={selectedEquipmentId} />
      )}
    </div>
  );
}

export default InstallationContainer;
