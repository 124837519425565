import React from 'react';
import CommonLayout from './CommonLayout';
import { useLocation } from 'react-router-dom';
import AuthenticationTemplate from 'features/authentication/authentication.template';

// const containerVariants = {
//   hidden: {
//     opacity: 0,
//     x: '100vw',
//   },
//   visible: {
//     opacity: 1,
//     x: 0,
//     transition: {
//       type: 'spring',
//       mass: 0.4,
//       damping: 8,
//       when: 'beforeChildren',
//       staggerChildren: 0.4,
//     },
//   },
//   exit: {
//     x: '-100vw',
//     transition: {
//       ease: 'easeInOut',
//     },
//   },
// };

const AuthLayout = () => {
  // const navigate = useNavigation();
  const { pathname } = useLocation();

  return (
    <AuthenticationTemplate templateFor={pathname}>
      <CommonLayout />
    </AuthenticationTemplate>
  );
};

// const pageTransition = {
//   duration: 0.5,
// };

export default React.memo(AuthLayout);
