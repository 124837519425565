/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { dashboardQuery } from '../../dashboard.query';
import classes from './RequestForTransfer.module.scss';
import closeIcon from '../../../../assets/closeIcon.svg';
import emailUs from '../../../../assets/dashboardICons/Message_light.svg';
import { getUserSFDetails } from 'utils/getUserAccount';

const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '300px',
  },
};

const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  fontSize: '18px',
  padding: '10px',
};
const paraGraph = {
  fontSize: '14px',
  padding: '10px',
};
const closeIconStyle = {
  float: 'right',
};
const RequestForTransfer = ({ open, setOpen, eq }) => {
  const handleClose = () => setOpen(false);
  const { mutate, status, data } = dashboardQuery.useMutationrequestTransfer();
  const handleEmail = () => {
    const userId = getUserSFDetails().id;
    const payload = {
      userID: userId,
      equipmentNumber: eq.equipmentNumber,
      productName: eq.productName,
      productCode: eq.productCode,
    };
    mutate(payload);
    setOpen(false);
  };
  useEffect(() => {
    if (data && status === 'success') {
      setOpen(false);
    }
  }, [data, status]);
  return (
    <div className={classes.RegisterEquipt_Modal}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={modalTitle}
          >
            Request For Transfer
            <Link
              onClick={handleClose}
              style={closeIconStyle}
              className={classes.closeIcon}
            >
              <img src={closeIcon} alt="closeIcon" />
            </Link>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={paraGraph}
          >
            <div className={classes.contactUs}>
              <div
                role="button"
                className={classes.writeUs}
                onClick={handleEmail}
              >
                <p className={classes.mailSend}>
                  <p className={classes.mailImg}>
                    <img src={emailUs} alt="Email Details" />
                  </p>
                  <p className={classes.blueClr}>Mail Us</p>
                  {/* <p>info@vsgdover.com</p> */}
                </p>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default React.memo(RequestForTransfer);
