import { useFormik } from 'formik';
import * as yup from 'yup';

const f = {
  rowOne: [
    {
      label: 'Business Name',
      id: 'name',
      required: true,
      name: 'name',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Business Name',
    },
  ],
  rowTwo: [
    {
      label: 'Address 1',
      id: 'address1',
      required: true,
      name: 'address1',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter House Number And Street Name',
    },
    {
      label: 'Address 2',
      id: 'address2',
      name: 'address2',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Landmark',
    },
  ],
  rowThree: [
    {
      label: 'Country',
      id: 'country',
      required: true,
      name: 'country',
      elementName: 'dropdown',
      type: 'dropdown',
      placeholder: 'United States',
      options: [],
    },
    {
      label: 'State',
      id: 'state',
      required: true,
      name: 'state',
      elementName: 'dropdown',
      type: 'dropdown',
      placeholder: 'Enter State',
      options: [],
    },
  ],
  rowFour: [
    {
      label: 'City',
      id: 'city',
      required: true,
      name: 'city',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter City',
    },
    {
      label: 'ZIP Code',
      id: 'zip',
      required: true,
      name: 'zip',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter ZIP Code',
      maxLength: '7',
    },
  ],
  rowFive: [
    {
      label: 'Phone',
      id: 'phoneNumber',
      required: true,
      name: 'phoneNumber',
      elementName: 'number',
      type: 'text',
      placeholder: 'Enter Your Phone Number',
      // minLength: '10',
    },
    // {
    //   label: 'Email Address',
    //   id: 'email',
    //   name: 'email',
    //   elementName: 'input',
    //   type: 'email',
    //   required: true,
    //   placeholder: 'Enter Email Address',
    //   maxLength: '80',
    // },
  ],
};

const formFeilds = [
  {
    label: 'Business Name',
    id: 'locationName',
    required: true,
    name: 'locationName',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Business Name',
  },
  {
    label: 'Address 1',
    id: 'address1',
    required: true,
    name: 'address1',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter House Number And Street Name',
  },
  {
    label: 'Address 2',
    id: 'address2',
    name: 'address2',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Landmark',
  },
  {
    label: 'Country 1',
    required: true,
    id: 'countryName',
    name: 'countryName',
    elementName: 'input',
    type: 'text',
    placeholder: 'United States',
  },
  {
    label: 'ZIP Code',
    id: 'zip',
    required: true,
    name: 'zip',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter ZIP Code',
    minLength: '5',
    maxLength: '7',
  },
  {
    label: 'City',
    id: 'cityName',
    required: true,
    name: 'cityName',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter City',
  },
  {
    label: 'State',
    id: 'stateName',
    required: true,
    name: 'stateName',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter State',
  },
  {
    label: 'Phone',
    id: 'contactNumber',
    required: true,
    name: 'contactNumber',
    elementName: 'number',
    type: 'text',
    placeholder: 'Enter Your Phone Number',
  },
  // {
  //   label: 'Email Address',
  //   id: 'email',
  //   name: 'email',
  //   elementName: 'input',
  //   type: 'email',
  //   required: true,
  //   placeholder: 'Enter Email Address',
  // },
];

const validations = yup.object().shape({
  city: yup
    .string()
    .required('City Name Required!')
    /*
    Poojitha
    Below regex is used for adding validations to State, location name , country, and city fields. Following are scenarios
     #5686%^& -> Not Accept
     A@1 -> Not Accept
     name -> Accept
    */
    .matches(/^[a-zA-Z\s]*$/, 'Special Characters are not allowed!'),
  zip: yup
    .string()
    .required('Zip code Required!')
    .max(7, 'Zip code should contain 7 digits')
    .matches(/^[a-zA-Z0-9 ]*$/, 'Please Enter a Valid Zip'),
  address1: yup
    .string()
    .required('Address1 is Required!')
    .matches(
      /^(?=.*[a-zA-Z])[ ,-:#&'.0-9a-zA-Z]*$/,
      'please Enter a Valid Address1',
    ),
  address2: yup
    .string()
    .matches(
      /^(?=.*[a-zA-Z])[ ,-:#&'.0-9a-zA-Z]*$/,
      'please Enter a Valid Address2',
    ),
  state: yup
    .string()
    .required('State Name is Required!')
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid State'),
  country: yup
    .string()
    .required('Country Name is Required!')
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid Country'),
  name: yup
    .string()
    .required('Business Name is Required!')
    // .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid Business Name'),
    .matches(
      /^(?=.*[a-zA-Z])[ ,-:#&'.0-9a-zA-Z]*$/,
      'Please Enter a Valid Business Name',
    ),
  // email: yup
  //   .string()
  //   .email('Please Enter a Valid Email')
  //   .matches(
  //     /^[a-z0-9A-Z]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
  //     'Please Enter a Valid Email',
  //   )
  //   .required('Email Required!'),
  phoneNumber: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number should contain 10 digits')
    .required('Phone Number Required!'),
});

const initialFormValues = {
  country: 'US',
  city: '',
  address1: '',
  state: 'NY',
  name: '',
  phoneNumber: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { f, formFeilds, validations, useFormHook };
