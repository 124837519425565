import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import classes from './ProductHeader.module.scss';
import regSuccess from '../../../assets/regSuccess.svg';
import { installationStore } from '@Store/installationStore';
import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';

const ProductHeader = ({ data }) => {
  const steps = installationStore((state) => state.steps);
  const { currentStep } = steps || {};
  const user = getUserSFDetails();
  const plusCount = isEndUser(user.userRole) ? 1 : 0;
  const statusId = localStorage.getItem('statusId');
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;
  return (
    <div>
      {currentStep <= 3 + plusCount && !valueFalg ? (
        <div className={classes.productHeader}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} className={classes.rotaryHeadSec}>
                <p className={classes.subHead}>Product Name</p>
                <h2 className={classes.hoverText}>
                  <h2 className={classes.rotaryTitle}>{data?.productName}</h2>
                  <span className={classes.tooltip}>{data?.productName}</span>
                </h2>
              </Grid>
              <Grid item xs={6} md={2} sm={3}>
                <p className={classes.subHead}>Serial Number</p>
                <p className={classes.subDetails}>{data?.equipmentNumber}</p>
              </Grid>
              <Grid item xs={6} md={2} sm={3}>
                <p className={classes.subHead}>Product Code</p>
                <p className={classes.subDetails}>{data?.productCode}</p>
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <p className={classes.subHead}>Distributor</p>
                <p className={classes.subDetails}>{data?.distributorName}</p>
              </Grid>
            </Grid>
          </Box>
        </div>
      ) : (
        !valueFalg && (
          <div className={classes.productSuccessHead}>
            <div>
              <img src={regSuccess} alt="regSuccessIcon" />

              <h2>
                Congratulations! Your Equipment Has Been Successfully Registered
              </h2>
            </div>
          </div>
        )
      )}

      <div className={classes.halfLeftCircle}></div>

      <div className={classes.halfRightCircle}></div>
    </div>
  );
};

export default React.memo(ProductHeader);
