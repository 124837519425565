import React from 'react';
import classes from './NoResults.module.scss';
import notFoundImg from '../../../assets/Notfound.svg';

const NoResultsFound = ({ handleClick }) => {
  return (
    <div className={classes.noResults}>
      {/* <div className={classes.face}>
        <div className={classes.band}>
          <div className={classes.red}></div>
          <div className={classes.white}></div>
          <div className={classes.blue}></div>
        </div>
        <div className={classes.eyes}></div>
        <div className={classes.dimples}></div>
        <div className={classes.mouth}></div>
      </div> */}
      <div className={classes.notFoundimage}>
        <img src={notFoundImg} alt="Equipment not found" />
      </div>
      <h1>No Equipment Found!</h1>
    </div>
  );
};

export default NoResultsFound;
