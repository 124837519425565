import { useFormik } from 'formik';
import * as yup from 'yup';

const formFeilds = [
  {
    label: 'Username',
    id: 'username',
    name: 'username',
    required: true,
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Email Address',
  },
];

const validations = yup.object().shape({
  username: yup
    .string()
    .email('Please Enter A Valid Email')
    .required('Email Address Required!')
    .matches(
      /^[a-z0-9A-Z._#-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
      'Please Enter A Valid Email',
    ),
});

const initialFormValues = {
  username: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { formFeilds, validations, useFormHook };
