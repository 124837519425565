import { useFormik } from 'formik';
import * as yup from 'yup';

const formFeilds = [
  {
    label: 'Company / Installer Name',
    required: true,
    id: 'companyName',
    name: 'companyName',
    elementName: 'input',
    type: 'text',
    placeholder: 'Enter Company / Installer Name',
  },
  {
    label: 'Installer Contact Number',
    required: true,
    id: 'installerNumber',
    name: 'installerNumber',
    elementName: 'number',
    type: 'text',
    placeholder: 'Enter Installer Contact Number',
  },
  {
    label: 'Installer Email Address',
    required: true,
    id: 'installerEmail',
    name: 'installerEmail',
    elementName: 'input',
    type: 'email',
    placeholder: 'Enter Installer Email Address',
  },
];

const validations = yup.object().shape({
  companyName: yup
    .string()
    .required('Company/Installer Name Required!')
    /*
    Poojitha
    Below regex is used for adding validation company name fields. Following are scenarios
     #5686%^& -> Not Accept
     A@1 -> Accept
     Company -> Accept
    */
    .matches(
      /^(?=.*[a-zA-Z])[!@#$%^&*(),.?":{}|<>0-9a-zA-Z ;_+=~`'\\/\[\]\|\\:;'\"<,.>?-]+$/,
      'Please Enter a Valid Company Name',
    ),
  installerEmail: yup
    .string()
    .email('Please Enter a Valid Email')
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
      'Please Enter a Valid Email',
    )
    .required('Email Required!'),
  installerNumber: yup
    .string()
    .trim()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format')
    .min(
      10,
      'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
    )
    .max(
      15,
      'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
    )
    .required('Contact Number Required!'),
});

const initialFormValues = {
  companyName: '',
  installerEmail: '',
  installerNumber: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { formFeilds, validations, useFormHook };
