import { useFormik } from 'formik';
import * as yup from 'yup';

const f = {
  rowOne: [
    {
      label: 'First Name',
      id: 'fullName',
      required: true,
      name: 'fullName',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Your First Name',
    },
  ],
  rowTwo: [
    {
      label: 'Last Name',
      id: 'lastName',
      required: true,
      name: 'lastName',
      elementName: 'input',
      type: 'text',
      placeholder: 'Enter Your Last Name',
    },
  ],
  rowThree: [
    {
      label: 'Contact Number',
      id: 'contactNumber',
      required: true,
      name: 'contactNumber',
      elementName: 'number',
      type: 'text',
      placeholder: 'Enter Contact Number',
    },
  ],
  rowFive: [
    {
      label: 'Email Address',
      id: 'email',
      name: 'email',
      required: true,
      elementName: 'input',
      type: 'email',
      placeholder: 'Enter Equipment owner email address',
    },
  ],
};

const validations = yup.object().shape({
  fullName: yup
    .string()
    .required('First Name Required!')
    /*
      Poojitha
      Below regex is used for adding validations to first name and last name fields. Following are scenarios
       #5686%^& -> Not Accept
       A@1 -> Not Accept
       name -> Accept
      */
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid First Name'),
  lastName: yup
    .string()
    .required('Last Name Required!')
    .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid Last Name'),
  email: yup
    .string()
    .email('Please Enter a Valid Email')
    .matches(
      /^[a-zA-Z0-9_.-]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
      'Please Enter a Valid Email',
    )
    .required('Email Required!'),
  contactNumber: yup
    .string()
    .trim()
    .matches(
      /^\(\d{3}\) \d{3}-\d{4}$/,
      'Please enter valid 10 digits Contact Numbers',
    )
    // .min(
    //   10,
    //   'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
    // )
    // .max(
    //   15,
    //   'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
    // )
    .required('Contact Number Required!'),
});

const initialFormValues = {
  fullName: '',
  email: '',
  lastName: '',
  contactNumber: '',
};
const useFormHook = (
  onSubmit,
  initialValues = initialFormValues,
  validationSchema = validations,
) => {
  if (!initialValues) {
    initialValues = initialFormValues;
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};

export { f, validations, useFormHook };

// import { useFormik } from 'formik';
// import * as yup from 'yup';

// const formFeilds = [
//   {
//     label: 'First Name',
//     id: 'fullName',
//     required: true,
//     name: 'fullName',
//     elementName: 'input',
//     type: 'text',
//     placeholder: 'Enter Your First Name',
//   },
//   {
//     label: 'Last Name',
//     id: 'lastName',
//     required: true,
//     name: 'lastName',
//     elementName: 'input',
//     type: 'text',
//     placeholder: 'Enter Your Last Name',
//   },
//   {
//     label: 'Contact Number',
//     id: 'contactNumber',
//     required: true,
//     name: 'contactNumber',
//     elementName: 'number',
//     type: 'text',
//     placeholder: 'Enter Contact Number',
//   },
//   {
//     label: 'Email Address',
//     id: 'email',
//     name: 'email',
//     required: true,
//     elementName: 'input',
//     type: 'email',
//     placeholder: 'Enter Email Address',
//   },
// ];

// const validations = yup.object().shape({
//   fullName: yup
//     .string()
//     .required('First Name Required!')
//     /*
//     Poojitha
//     Below regex is used for adding validations to first name and last name fields. Following are scenarios
//      #5686%^& -> Not Accept
//      A@1 -> Not Accept
//      name -> Accept
//     */
//     .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid First Name'),
//   lastName: yup
//     .string()
//     .required('Last Name Required!')
//     .matches(/^[a-zA-Z\s]*$/, 'Please Enter a Valid Last Name'),
//   email: yup
//     .string()
//     .email('Please Enter a Valid Email')
//     .matches(
//       /^[a-z0-9A-Z]+@[a-zA-Z]+\.[a-zA-Z]{2,5}$/,
//       'Please Enter a Valid Email',
//     )
//     .required('Email Required!'),
//   contactNumber: yup
//     .string()
//     .trim()
//     .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number format')
//     .min(
//       10,
//       'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
//     )
//     .max(
//       15,
//       'Contact Number Should Contain Max Numbers is 15 and Minimum Numbers is 10',
//     )
//     .required('Contact Number Required!'),
// });

// const initialFormValues = {
//   fullName: '',
//   email: '',
//   lastName: '',
//   contactNumber: '',
// };
// const useFormHook = (
//   onSubmit,
//   initialValues,
//   validationSchema = validations,
// ) => {
//   if (!initialValues) {
//     initialValues = initialFormValues;
//   }
//   const formik = useFormik({
//     initialValues,
//     validationSchema,
//     onSubmit,
//   });

//   return formik;
// };

// export { formFeilds, validations, useFormHook };
