import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import classes from './DistributorDashboard.module.scss';
import { InputField } from '@Components/Input';
import { Button } from '@Components/Button';
import Alert from '@mui/material/Alert';
import { getUserSFDetails } from 'utils/getUserAccount';
import { authService } from '@Services/auth';
import closeIcon from '../../../assets/closeIcon.svg';
import RadioGroup from '@mui/material/RadioGroup';
import { StyledRadioControl } from '@Components/Radio';

const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: '0',
  outline: '0',
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '284px',
  },
  '@media (min-width: 768px) and (max-width: 915px) and (orientation:landscape)':
    {
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '284px',
    },
  '@media (min-width: 501px) and (max-width: 767px) ': {
    top: '57%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '450px',
    height: '284px',
  },
  '@media (max-width: 500px)': {
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '350px',
    marginTop: '9%',
    height: '284px',
  },
};

const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  fontSize: '18px',
  padding: '10px',
};
const paraGraph = {
  fontSize: '14px',
  padding: '15px',
  paddingBottom: '0',
  overflow: 'hidden',
  marginTop: '0',
};

const DistributorDashboard = ({ open, setOpen, onSucessPopUp }) => {
  const [value, setValue] = useState('');
  const [distributorFlag, setDistributorFlag] = useState(true);
  const [popupType, setPopupType] = useState('');
  const [customerDetails, setCustomerDetails] = useState('Customer');
  const [error, setError] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleSubmit = () => {
    if (value.trim() !== '' && error === '') {
      handlePopupSubmit(value);
    }
  };
  const validateValue = (inputValue) => {
    const regex = /^[0-9]*$/;
    if (inputValue.trim() === '') {
      return 'This Field is Required!';
    }
    if (!regex.test(inputValue)) {
      return 'Value is invalid';
    }
    return '';
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    setError(validateValue(e.target.value));
    setDistributorFlag(true);
  };
  const isOracleNumber = customerDetails === 'Oracle' ? true : false;
  const updateDistributor = (companyNumber) => {
    const user = getUserSFDetails();

    authService
      .updateDistributor({ userId: user.id, companyNumber, isOracleNumber })
      .then(() => {
        onSucessPopUp();
      })
      .catch((err) => {
        setErrorStatus(err?.response?.data?.message);
        setDistributorFlag(false);
      });
  };

  const updateInstaller = (companyNumber) => {
    const user = getUserSFDetails();
    authService
      .updateInstaller({ userId: user.id, companyNumber, isOracleNumber })
      .then(() => {
        onSucessPopUp();
      })
      .catch((err) => {
        setErrorStatus(err?.response?.data?.message);
        setDistributorFlag(false);
      });
  };

  const handlePopupSubmit = (companyNumber) => {
    const user = getUserSFDetails();
    if (user.userRole === 'Distributor') {
      updateDistributor(companyNumber);
    } else {
      updateInstaller(companyNumber);
    }
  };

  useEffect(() => {
    const user = getUserSFDetails();
    setPopupType(user.userRole);
  }, []);

  const handleChange1 = ({ target }) => {
    setCustomerDetails(target?.value || 'Customer');
  };

  useEffect(() => {
    setValue('');
    setError('');
  }, [customerDetails]);

  const handleInputBlur = () => {
    setError(validateValue(value));
  };

  return (
    <div className={classes.RegisterEquipt_Modal}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={modalTitle}
            className={classes.titleInfo}
          >
            {popupType} Information
            <Link
              onClick={handleClose}
              className={classes.closeIcon}
              style={{ float: 'right' }}
            >
              <img src={closeIcon} alt="closeIcon" />
            </Link>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={paraGraph}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={handleChange1}
              value={customerDetails}
            >
              <div className={classes.radioBtnsWrapper}>
                <StyledRadioControl value="Customer" label="Customer Number" />
                <StyledRadioControl value="Oracle" label="Oracle Number" />
              </div>
            </RadioGroup>
            <div className={classes.distributorDetails}>
              <InputField
                label={`Enter ${customerDetails} Number`}
                type="text"
                name="DistributorId"
                placeholder={`Enter ${customerDetails} Number Here`}
                value={value}
                onChange={handleChange}
                onBlur={handleInputBlur}
                className={error && classes.fieldRow}
              />
            </div>
            {error && (
              <div
                style={{
                  color: '#c51e1e ',
                  marginTop: '-15px',
                  fontSize: '12px',
                }}
              >
                {error}
              </div>
            )}
            <div className={classes.verifyButton}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Verify
              </Button>
            </div>
            <div style={{ padding: '0px' }}>
              {errorStatus && distributorFlag === false && value !== '' ? (
                <Alert
                  severity="error"
                  style={{ padding: '0px', marginTop: '7px' }}
                >
                  {errorStatus}
                </Alert>
              ) : null}
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default React.memo(DistributorDashboard);
