/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import classes from './RegisterEquipLogin.module.scss';
import qrCodeIcon from '../../../assets/dashboardICons/qr-code.svg';
import uploadIcon from '../../../assets/dashboardICons/UploadImg.svg';
import useQRCodeScan from 'hooks/useQR';
import { useTheme } from '@mui/material/styles';
import { dashBoardService } from '@Services/dahboard';
import { toast } from 'react-toastify';
import { MappedInput } from '@Components/Input';
import { formFeilds, useFormHook } from './RegisterEquipLogin.form.initializer';
import fileUploadDisabled from './../../../assets/fileUploadDisbled.svg';
import qrCodeDisabled from './../../../assets/qrCodeDisabled.svg';
import { authService } from '@Services/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import AppleAssociation from '../../../apple-file';

const singInButton = {
  padding: '16px 20px 16px 20px ',
  fontSize: '14px',
  width: '100%',
  height: '40px',
  borderRadius: '2',
  fontFamily: 'Montserrat',
  fontWeight: '700',
};

function RegisterEquipLogin() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const { startQrCode, decodedQRData, stopQrCode, uploadQrimage } =
    useQRCodeScan({
      qrcodeMountNodeID: 'qrcodemountnode',
    });
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const alphaNumicRegx = /\/\/(.+)$/;
  const [finalData, setFinalData] = useState('');
  const [manageState, setManageState] = useState({
    value: '',
    isError: false,
    isDecodeed: false,
  });
  const [serialNumber, setSerialNumber] = useState('');
  const [errorPopup, setErrorPopup] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [messageFlag, setMessageFlag] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [recheck, setReCheck] = useState([]);

  // const isLandingPage = true;

  useEffect(() => {
    setIsVisible(true);
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [responseData === 2]);

  const startQrScanS = () => {
    setOpen(true);
    setTimeout(() => {
      startQrCode();
    }, 300);
  };
  localStorage.setItem('statusId', JSON.stringify(responseData));
  const handleSSO = async (state = '') => {
    if (state !== '' && !!state && state !== null) {
      dashBoardService.getProductStatus(state).then((res) => {
        setResponseData(res?.response?.statusId);
        if (res?.response?.statusId === 1) {
          toast.error('Product Already Registered!!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setSerialNumber('');
        }
        if (res?.response === null && state !== null) {
          // toast.error('Equipment not found', {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          // if (recheck.includes(serialNumber) && count !== 0) {
          //   setCount(count + 1);
          //   toast.error(
          //     'Equipment wasn’t found. Please contact customer service at technicalsupport@vsgdover.com.',
          //     {
          //       position: toast.POSITION.TOP_CENTER,
          //     },
          //   );
          //   return;
          // } else {
          //   setCount(count + 1);
          //   setReCheck(serialNumber);
          //   toast.error(
          //     'Equipment wasn’t found. Please manually enter the serial number.',
          //     {
          //       position: toast.POSITION.TOP_CENTER,
          //     },
          //   );
          // }
          const indexMatch = recheck.findIndex((item) => {
            return state.toLowerCase() === item.serialNumber.toLowerCase();
          });
          if (indexMatch >= 0) {
            const matchedItem = recheck[indexMatch];
            matchedItem.tries = matchedItem.tries + 1;
            // setCount(count + 1);
            if (matchedItem.tries === 2) {
              toast.error(
                'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            } else if (matchedItem.tries > 2) {
              toast.error(
                'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number. ',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            } else {
              toast.error(
                'Equipment wasn’t found. Please manually enter the serial number.',
                {
                  position: toast.POSITION.TOP_CENTER,
                },
              );
            }
          } else {
            const serialNumStore = { serialNumber: state, tries: 1 };
            setReCheck((oldData) => {
              return [...oldData, serialNumStore];
            });
            toast.error(
              'Equipment wasn’t found. Please manually enter the serial number.',
              {
                position: toast.POSITION.TOP_CENTER,
              },
            );
          }

          setSerialNumber('');
        }
        if (res?.response?.statusId === 2) {
          setMessageFlag((messageFlag) => !messageFlag);

          authService
            .loginIDP(`${form.values.username}`)
            .then((data) => {
              if (data.status === 200) {
                instance.loginRedirect({
                  state,
                  authority: data.data.response.policyUrl,
                  loginHint: `${form.values.username}`,
                });
                localStorage.setItem('policyURL', data.data.response.policyUrl);
                const parts = data.data.response.policyUrl.split('/');

                const fourthPart = parts[5];
                console.warn(fourthPart, 'fourthPart policyUrl');
                localStorage.setItem('policy', fourthPart);
              }

              // localStorage.setItem('statusId', JSON.stringify(responseData));
            })
            .catch((err) => {
              if (err.response.data.statusCode === 400) {
                instance.loginRedirect({
                  state,
                  authority:
                    // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
                    'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
                  // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
                  // loginHint: `${form.values.username}`,
                });
              }
            });
        }
      });

      setMessageFlag(false);
    }
  };
  useEffect(() => {
    const { data } = decodedQRData;
    const url = data;
    const urlParams = new URLSearchParams(url);
    const paramValue = urlParams.get('snum');
    const serialNumberInfo = paramValue ? paramValue : data;
    const isValidData = alphaNumicRegx.test(serialNumberInfo || '');
    const serialNo = !isValidData ? serialNumberInfo : '';

    setManageState({
      ...manageState,
      value: serialNo,
      isError: data ? isValidData : false,
      isDecodeed: !!serialNo,
    });

    setFinalData(serialNo);
    setSerialNumber((sr) => serialNo);
    if (serialNo) {
      handleSSO(serialNo);
    }

    if (data && open) {
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  }, [decodedQRData]);

  const handleClose = () => {
    setOpen(false);
    stopQrCode();
  };

  useEffect(() => {
    if (manageState.isError === true) {
      setTimeout(function () {
        setErrorPopup(false);
      }, 500);
    }
    if (manageState.isError === false) {
      setErrorPopup(true);
    }
  }, [manageState.isError === true]);

  const handleupload = (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore
      return;
    }
    setOpen(true);
    // setIsImageupload(true);
    const imageFile = e.target.files[0];
    setTimeout(() => {
      uploadQrimage(imageFile);
      e.target.value = '';
    }, 300);
  };
  const onSubmit = (values) => {};
  const form = useFormHook(onSubmit);
  const { isValid, values, dirty } = form;
  const handleSSO1 = () => {
    authService
      .loginIDP(`${form.values.username}`)
      .then((data) => {
        if (data.status === 200) {
          instance.loginRedirect({
            authority: data.data.response.policyUrl,
            loginHint: `${form.values.username}`,
          });
          localStorage.setItem('policyURL', data.data.response.policyUrl);
          const parts = data.data.response.policyUrl.split('/');

          const fourthPart = parts[5];
          console.warn(fourthPart, 'fourthPart policyUrl');
          localStorage.setItem('policy', fourthPart);
        }
      })
      .catch((err) => {
        if (
          err.response.data.statusCode === 400 &&
          err.response.data.message.toLowerCase() === 'user not found'
        ) {
          instance.loginRedirect({
            authority:
              // 'https://cxportal.b2clogin.com/tfp/cxportal.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
              'https://cxportaluat.b2clogin.com/tfp/cxportaluat.onmicrosoft.com/B2C_1A_LWCPORTAL_SIGNIN_POLICY_VSGPR',
            // 'https://cxportalprod.b2clogin.com/tfp/cxportalprod.onmicrosoft.com/b2c_1a_lwcportal_signin_policy_vsgna_combined',
            // loginHint: `${form.values.username}`,
          });
        }
      });
  };

  return (
    <div className={classes.registerEquipPageContainer}>
      <AppleAssociation />
      <div className={classes.rightContainer}>
        <h2>New Equipment Registration</h2>
        <form className={classes.userName}>
          <span className={classes.textStyles}>
            Please enter username and proceed.
          </span>
          <MappedInput fields={formFeilds} form={form} />
        </form>
        <div className={classes.registerNewEquipPopup}>
          <div className={classes.regRightContainer}>
            <div>
              <div className={classes.userNameCnt}>
                <form>
                  <p>Search Serial Number</p>
                  <input
                    type="text"
                    placeholder="Enter valid serial number"
                    label="Serial Number"
                    name="serialNumber"
                    onChange={(val) => setSerialNumber(val.target.value)}
                    value={serialNumber}
                    disabled={!dirty || !isValid}
                  />
                  <input
                    type="button"
                    onClick={() => handleSSO(serialNumber)}
                    value="SEARCH"
                    disabled={!dirty || !isValid}
                  />
                </form>
              </div>
            </div>
            {!decodedQRData.data && responseData === 2 && isVisible && (
              <p className={classes.notifyMessageStyles}>
                Redirecting, please wait..
              </p>
            )}
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth={!fullScreen ? 'sm' : ''}
              fullWidth={true}
              fullScreen={fullScreen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                className={classes.scanQRCode}
              >
                {'Scan Qr Code'}
              </DialogTitle>
              <DialogContent
                sx={{ minHeight: '250px' }}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div
                  id="qrcodemountnode"
                  style={{ width: '330px', height: '330px' }}
                ></div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  className={classes.scanButton}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <p className={classes.orText}>
            <span className={classes.orLine}></span>
            <span className={classes.orSpan}>OR</span>
            <span className={classes.orLine}></span>
          </p>
          <div className={classes.leftContainer}>
            <p>QR / Bar Code Scanner</p>
            <div className={classes.QRContainer}>
              <Button disabled={!dirty || !isValid}>
                <div
                  className={classes.scannerImg}
                  onClick={startQrScanS}
                  role="button"
                >
                  {!dirty || !isValid ? (
                    <img src={qrCodeDisabled} alt="qrCodeDisabled" />
                  ) : (
                    <img src={qrCodeIcon} alt="Scan-QR-Code" />
                  )}

                  <p
                    style={{
                      textTransform: 'capitalize',
                      fontSize: '13px',
                      color: 'black',
                    }}
                  >
                    Scan QR / Bar Code
                  </p>
                </div>
              </Button>
              <div className={classes.uploadQrImg}>
                <Button variant="outlined" disabled={!dirty || !isValid}>
                  <label name="qr-input-file">
                    {!dirty || !isValid ? (
                      <img src={fileUploadDisabled} alt="fileUploadDisabled" />
                    ) : (
                      <img src={uploadIcon} alt="Upload-QR-Code" />
                    )}

                    <p>Upload QR / Bar Code Image</p>
                    <input
                      type="file"
                      id="qr-input-file"
                      accept="image/*"
                      onChange={handleupload}
                      // capture
                    ></input>
                  </label>
                </Button>
              </div>
            </div>
            {decodedQRData.data && responseData === 2 && isVisible && (
              <p className={classes.notifyMessageStyles}>
                Redirecting, please wait..
              </p>
            )}
            <p className={classes.orText}>
              <span className={classes.orLine}></span>
              <span className={classes.orSpan}>OR</span>
              <span className={classes.orLine}></span>
            </p>
          </div>
          <div className={classes.loginIncnt}>
            <div className={classes.submit}>
              <Button
                type="submit"
                variant="contained"
                className={
                  !dirty || !isValid
                    ? classes.disabledButton
                    : classes.buttonWidth
                }
                onClick={() => handleSSO1()}
                style={singInButton}
                disabled={!dirty || !isValid}
              >
                LOGIN / REGISTER
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterEquipLogin;
