import { api } from '@Services/config';

const getProducts = async (url) =>
  api.get(`Equipment/GetUserEquipments/${url}`);

const getProduct = async (url) =>
  api.get(`Equipment/GetEquipment/${url}`).then((res) => res.data);

// const getProduct = async (equipmentNumber, isLandingPage) => {
//   console.warn(isLandingPage, 'isLandingPage');
//   const url = isLandingPage
//     ? `Equipment/GetEquipment/${equipmentNumber}?isLandingPage=${isLandingPage}`
//     : `Equipment/GetEquipment/${equipmentNumber}`;
//   return api.get(url).then((res) => res.data);
// };
const getProductStatus = async (equipmentNumber) =>
  api
    .get(`Equipment/GetEquipmentStatus/${equipmentNumber}`)
    .then((res) => res.data);

const requestForTransfer = async (payload = {}) => {
  const url = `Equipment/SendTransferEquipmentMail`;
  return api.post(url, payload);
};

const dashBoardService = {
  getProductStatus,
  getProducts,
  getProduct,
  requestForTransfer,
};

export default dashBoardService;
