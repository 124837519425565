import React from 'react';
import InputField from './Input';
import InputPassword from './InputPassword';
import InputNumber from './InputNumber';
import DropdownField from './Dropdown';

export function InputController({ el, form, className }) {
  const getProps = {
    ...el,
    key: `${el.name}`,
    onChange: form.handleChange,
    value: form.values[el.name],
    errors: form.errors[el.name],
    name: el.name,
    onBlur: form.handleBlur,
    touched: form.touched[el.name],
    className: className,
  };

  switch (el.elementName) {
    case 'input':
      return <InputField {...getProps} />;
    case 'password':
      return <InputPassword {...getProps} />;
    case 'number':
      return <InputNumber {...getProps} />;
    case 'dropdown':
      return <DropdownField {...getProps} />;
    // case 'textArea':
    //   return <TextArea {...rest} />;
    // case 'select':
    //   return <Select {...rest} />;
    // case 'radio':
    //   return <RadioButtons {...rest} />;
    // case 'checkbox':
    //   return <CheckBoxes {...rest} />;
    default:
      return null;
  }
}

function MappedInput({ form, fields = [] }) {
  return fields.map((el) => (
    <InputController
      key={el.name}
      el={el}
      form={form}
      className={form.errors}
    />
  ));
}

export default React.memo(MappedInput);
