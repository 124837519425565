import React from 'react';
import MaskedInput from 'react-text-mask';
import classes from './Input.module.scss';
import classNames from 'classnames';

function InputNumber(props) {
  const phoneNumberMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      // eslint-disable-next-line react/prop-types
      props.onChange(e);
    }
  };

  const isInputError = props.errors && props.touched;
  const styles = {
    formControl: classes.formControl,
    error: 'input-error',
    border: '2px solid red',
  };

  const cx = classNames.bind(styles);

  const classList = cx({
    formControl: true,
    [classes.inputError]: isInputError,
    [props.className]: true,
  });

  return (
    <div
      className={classNames([
        classes.formBlock,
        'form-control-block',
        props.name,
      ])}
    >
      {props.label && (
        <label className={classes.formLabel} htmlFor="input-field">
          {props.label}{' '}
          {props.required && <span className={classes.formLabelReq}>*</span>}
        </label>
      )}
      <MaskedInput
        mask={phoneNumberMask}
        {...props}
        onChange={props.onChange}
        className={classList}
      />
      {isInputError && (
        <span className={classNames([classes.errorMsg, 'input-error-mgs'])}>
          {props.errors}
        </span>
      )}
    </div>
  );
}

export default React.memo(InputNumber);
