import React, { useCallback, useEffect, useState, useRef } from 'react';
import SearchResults from '@Components/SearchResults';
import { installationStore } from '@Store/installationStore';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import classes from './RAI.module.scss';
import { toast } from 'react-toastify';
import { instalationQuery } from 'features/Installation/Installation.query';
import { shallow } from 'zustand/shallow';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';

function RotaryAuthorizedInstaller() {
  const { setNextButton, setInstallationType, setStepPrev } =
    installationStore();
  const installationType = installationStore(
    (state) => state.installationType,
    shallow,
  );
  const existingZip = installationType?.zip || '';
  const [value, setValue] = useState(existingZip);
  const [payload, setPayload] = useState(existingZip);
  const [valueCheck, setValueCheck] = useState(false);
  const { setSpinner, isActive } = spinnerStore();
  const [searchResultsId, setSearchResultsId] = useState(
    installationType[existingZip] || '',
  );
  const userData = getUserSFDetails();
  const [fetch, { data, status }] = instalationQuery.useInstallersByZip(
    payload || userData.installerZipcode,
  );
  const [loadingFlag, setLoadingFlag] = useState(false);
  const formRef = useRef(null);
  const findZip = (event) => {
    event.preventDefault();
    setPayload(value);
    setTimeout(() => {
      fetch();
    }, 100);
    setLoadingFlag(true);
    setValueCheck(true);
    setSearchResultsId('');
  };

  useEffect(() => {
    setValueCheck(true);
    if (value) {
      setPayload(value);
      setSearchResultsId(installationType.id);
      setTimeout(() => {
        fetch();
        setNextButton(false);
        setLoadingFlag(true);
      }, 100);
    }
  }, []);

  const handleChange = useCallback(({ target }) => {
    setSearchResultsId(target.value);
    setNextButton(false);
  });
  useEffect(() => {
    if (!searchResultsId) {
      setNextButton(true);
    }
  }, [!searchResultsId]);
  const handleNextClick = () => {
    const item = data.find((ele) => ele.id === +searchResultsId);
    setInstallationType({ ...item }, true);
  };
  const handlePrevClick = () => {
    setStepPrev();
  };
  useClickHandlers(handleNextClick, handlePrevClick);

  /*
 * poojitha
 Below code is used to fix the issue of previous search results are displayed, when we enter any value in input field
 */
  useEffect(() => {
    if (data !== null && value === '' && status === 'success') {
      setValueCheck(false);
      setSearchResultsId('');
    }
  }, [value, status, data]);

  useEffect(() => {
    if (status === 'success') {
      setLoadingFlag(false);
    }
  }, [status === 'success']);

  /*
  Poojitha
  Below code is used for showing toast message, When response is null
  */
  useEffect(() => {
    if (data === null) {
      toast.error('Installers not found', {
        position: toast.POSITION.TOP_CENTER,
      });
      setValue('');
    }
  }, [data === null]);

  /*
  Poojitha
  Below code is used for disable the find button
  */
  const isInputValid = () => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(value);
  };
  const findZipCode = (event) => {
    setPayload(value);
    setNextButton(true);
    setTimeout(() => {
      fetch();
    }, 100);
    setValueCheck(true);
  };
  useEffect(() => {
    if (userData.installerZipcode) {
      setValue(userData.installerZipcode);
      findZipCode(userData.installerZipcode);
    }
  }, []);

  useEffect(() => {
    setSpinner(loadingFlag === true);
    if (data) {
      setSpinner('');
    }
  }, [loadingFlag]);
  return (
    <div className={classes.RAInstallationCont}>
      <div className={classes.instalMiddleRightCont}>
        <div className={classes.installRightCont}>
          <div className={classes.Register_rightContainer}>
            <div className={classes.LocationCont}>
              <div className={classes.LocationHead}>
                <h2>
                  Enter Zip Code to find Rotary Authorized Installer (RAI)
                  <span className={classes.astericSymb}>*</span>
                </h2>
                {/* <span>(Rotary Authorized Installer)</span> */}
              </div>
              <div className={classes.LocationFind}>
                <form
                  onSubmit={findZip}
                  className={classes.searchStyles}
                  ref={formRef}
                >
                  <input
                    type="text"
                    maxlength="7"
                    value={/^[a-zA-Z0-9 ]*$/.test(value) ? value : ''}
                    /*
                    Poojitha 
                    Added trim() method to remove the spaces 
                    */
                    onChange={(e) => {
                      setValue(e.target.value.trim());
                    }}
                    placeholder="Enter ZIP Code"
                  />
                  <input
                    type="submit"
                    value="FIND"
                    className={classes.bottonStyles}
                    disabled={!isInputValid() || value === ''}
                  />
                </form>
              </div>
              <div className={classes.results}>
                {data && value !== '' && valueCheck === true && (
                  /* Poojitha
                value !== '' is used to hide the dropdown. When values is empty
                 */
                  <SearchResults
                    items={data}
                    title={`Results of Zip Code - ${payload}`}
                    selectRowId={searchResultsId}
                    handleChange={handleChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RotaryAuthorizedInstaller;
