const pick = (obj, ...fields) => {
  const objx = fields.reduce(
    (acc, field) => ({ ...acc, [field]: obj[field] }),
    {},
  );
  return Object.entries(objx).reduce(
    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {},
  );
};
export default pick;
