/* eslint-disable no-lone-blocks */
/* eslint-disable no-console */
import React from 'react';
import { Hidden } from '@mui/material';
import classNames from 'classnames';
import { installationStore } from '@Store/installationStore';
import classes from './InstallationSteps.module.scss';
import { getUserSFDetails, isEndUser } from 'utils/getUserAccount';

const InstallationSteps = () => {
  const { steps } = installationStore();
  const user = getUserSFDetails();
  const setpsCount = isEndUser(user.userRole) ? [0, 1, 2, 3] : [0, 1, 2];
  const plusCount = setpsCount.length === 4 ? 1 : 0;
  const { currentStep } = steps || {};

  const cx = classNames.bind({});
  const endUserclassList = cx({
    [classes.active]: currentStep === 1,
    [classes.completed]: currentStep > 1,
  });

  const installationclassList = cx({
    [classes.active]: currentStep === 1 + plusCount,
    [classes.completed]: currentStep > 1 + plusCount,
  });

  const locationclassList = cx({
    [classes.active]: currentStep === 2 + plusCount,
    [classes.completed]: currentStep > 2 + plusCount,
  });

  const registrationclassList = cx({
    [classes.active]: currentStep === 3 + plusCount,
    [classes.completed]:
      currentStep > 3 + plusCount || currentStep === 3 + plusCount,
  });

  const statusId = localStorage.getItem('statusId');
  const valueFalg = user.userRole === 'CSR' && statusId === '1' ? true : false;

  const getBurrentStep = (idx) => {
    let id = idx;
    if (setpsCount.length === 3) {
      id = idx + 1;
    }
    switch (id) {
      case 0: {
        return (
          <li className={endUserclassList}>
            <span className={classes.numberSec}>{idx + 1}</span>
            <span className={classes.stepActive}>
              <Hidden smDown>Equipment </Hidden>Owner Selection
            </span>
          </li>
        );
      }
      case 1: {
        return (
          <>
            {setpsCount.length === 4 && (
              <span className={classes.horzLiner}></span>
            )}
            <li className={installationclassList}>
              <span className={classes.numberSec}>{idx + 1}</span>
              <span className={classes.stepActive}>Installation Type</span>
            </li>
          </>
        );
      }
      case 2: {
        return (
          <>
            <span className={classes.horzLiner}></span>
            <li className={locationclassList}>
              <span className={classes.numberSec}>{idx + 1}</span>
              <span className={classes.stepActive}>Location Details</span>
            </li>
          </>
        );
      }
      case 3: {
        return (
          <>
            <span className={classes.horzLiner}></span>

            <li className={registrationclassList}>
              <span className={classes.numberSec}>{idx + 1}</span>
              <span className={classes.stepActive}>
                {' '}
                <Hidden smDown>Registration </Hidden>Confirmation
              </span>
            </li>
          </>
        );
      }
      default:
        break;
    }
  };
  return (
    <div className={classes.installationNav}>
      {(user.userRole === 'Distributor' || user.userRole === 'Installer') &&
      currentStep !== 5 ? (
        <ol>{setpsCount.map((ele) => getBurrentStep(ele))}</ol>
      ) : user.userRole === 'Basic User' && currentStep !== 4 ? (
        <ol>{setpsCount.map((ele) => getBurrentStep(ele))}</ol>
      ) : user.userRole === 'CSR' && statusId === '2' && currentStep !== 5 ? (
        <ol>{setpsCount.map((ele) => getBurrentStep(ele))}</ol>
      ) : (
        !valueFalg && ''
      )}
    </div>
  );
};

export default InstallationSteps;
