import React, { useEffect } from 'react';
import classes from './RegSuccess.module.scss';
import useClickHandlers from '../hooks/useClickHandlers';
import { useNavigate } from 'react-router-dom';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { getUserSFDetails } from 'utils/getUserAccount';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@Components/Button';
import { instalationQuery } from './../Installation.query';
import texaIcon from './../../../assets/dashboardICons/texaIcon.svg';
import closeIcon from './../../../assets/closeIcon.svg';
import touchIcon from './../../../assets/touch_screen.png';
import { Link } from 'react-router-dom';
import { spinnerStore } from '@Store/spinnerStore';
function RegSuccess() {
  const navigate = useNavigate();
  const {
    locationDetails,
    installationType,
    selectedEquipmentId,
    equipmentTypes,
    setNextButton,
  } = installationStore();
  const {
    mutate,
    status,
    data: texaDetails,
  } = instalationQuery.useMutationtexaActivate();
  const userSFData = getUserSFDetails();
  const [cancelPopup, setCancelPopup] = React.useState(false);
  const [activatedPopup, setactivatedPopup] = React.useState(false);
  const { setSpinner, isActive } = spinnerStore();
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  useEffect(() => {
    setNextButton(false);
  }, []);
  const handleNextClick = () => {
    setTimeout(() => navigate('/dashboard'), 300);
  };
  useClickHandlers(handleNextClick);

  const { id } = useParams();
  const { data } = dashboardQuery.useProduct(userSFData.email, id, true);
  const eq = equipmentTypes?.find(
    (ele) => String(ele.id) === String(selectedEquipmentId),
  );
  const genrateStringByEqId = (idx) => {
    idx = String(idx);
    let string = '';
    if (idx === '1') {
      const { fullName, lastName } = installationType;
      string = fullName;
      string += lastName ? ', ' + lastName : '';
    }
    if (idx === '2') {
      const { name } = installationType;
      string = name;
    }

    if (idx === '3') {
      const { companyName } = installationType;
      string = companyName;
    }
    return string;
  };

  const getAddress = () => {
    const { address1, address2, city, state, country, zip } = locationDetails;
    return [address1, address2, city, state, country, zip]
      .filter((ele) => !!ele)
      .join(', ');
  };
  const Installer = genrateStringByEqId(selectedEquipmentId);
  const addressStr = getAddress();
  const endUserDetails = installationStore((state) => state.endUserDetails);

  const getCurrentDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const currentDate = `${month}/${day}/${year}`;
    return currentDate;
  };
  const handleTexaActivate = () => {
    const payload = {
      userId: userSFData.id,
      userEmail: userSFData.email,
      equipmentId: data?.equipmentId,
      equipmentNumber: data?.equipmentNumber,
      firstName: userSFData.firstName,
      lastName: userSFData.lastName,
    };
    mutate(payload);
    setactivatedPopup(true);
  };
  const handleClosed = () => {
    setCancelPopup(true);
    setactivatedPopup(false);
  };
  const activedCode = localStorage.getItem('activatedCode');
  return (
    <div>
      {endUserDetails?.id ? (
        <div>
          <div className={classes.regSuccessPage}>
            <div className={classes.regConfirmationCont}>
              <div className={classes.equipDetails}>
                <h2>Equipment Registration Details</h2>

                <div className={classes.SerailsNumber}>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Product Name</p>
                    <p className={classes.equipSubHeadDetail}>
                      {data?.productName}
                    </p>
                  </div>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Product Owner</p>
                    <p className={classes.equipSubHeadDetail}>
                      {endUserDetails.firstName} {endUserDetails.lastName}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Product Code</p>
                    <p className={classes.equipSubHeadDetail}>
                      {data?.productCode}
                    </p>
                  </div>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Serial Number</p>
                    <p className={classes.equipSubHeadDetail}>
                      {data?.equipmentNumber}
                    </p>
                  </div>
                </div>

                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Installer</p>
                    <p className={classes.equipSubHeadDetail}>{Installer}</p>
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Installer Type</p>
                    <p className={classes.equipSubHeadDetail}>
                      {eq?.installerType}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Distributor</p>
                    <p className={classes.equipSubHeadDetail}>
                      {data?.distributorName}
                    </p>
                  </div>
                  <div className={classes.equipDetailsList}>
                    <p className={classes.equipSubHead}>Registered By</p>

                    <p className={classes.equipSubHeadDetail}>
                      {userSFData.firstName} {userSFData.lastName}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.locationDetails}>
                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>
                      Address of the Equipment
                    </p>
                    <p className={classes.equipSubHeadDetail}>{addressStr}</p>
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Date of Registration</p>
                    <p className={classes.equipSubHeadDetail}>
                      {getCurrentDate()}
                    </p>
                  </div>
                </div>
                <div className={classes.SerailsNumber}>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Email</p>
                    <p className={classes.equipSubHeadDetail}>
                      {endUserDetails.email || userSFData.email || 'N/A'}
                    </p>
                    {/* <p className={classes.hoverText}>
                      <p className={classes.rotaryTitle}>
                        {locationDetails?.email}
                      </p>
                      <span className={classes.tooltip}>
                        {locationDetails?.email}
                      </span>
                    </p> */}
                  </div>
                  <div className={classes.locationDetailList}>
                    <p className={classes.equipSubHead}>Phone</p>
                    <p className={classes.equipSubHeadDetail}>
                      {locationDetails?.phoneNumber || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.regSuccessPage}>
          <div className={classes.regConfirmationCont}>
            <div className={classes.equipDetails}>
              <h2>Equipment Registration Details</h2>
              <div className={classes.equipDetailsList}>
                <p className={classes.equipSubHead}>Product Name:</p>
                <p className={classes.equipSubHeadDetail}>
                  {data?.productName}
                </p>
              </div>
              <div className={classes.SerailsNumber}>
                <div className={classes.equipDetailsList}>
                  <p className={classes.equipSubHead}>Product Code:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {data?.productCode}
                  </p>
                </div>
                <div className={classes.equipDetailsList}>
                  <p className={classes.equipSubHead}>Serial Number:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {data?.equipmentNumber}
                  </p>
                </div>
              </div>
              <div className={classes.SerailsNumber}>
                <div className={classes.equipDetailsList}>
                  <p className={classes.equipSubHead}>Installer Type:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {eq?.installerType}
                  </p>
                </div>
                <div className={classes.equipDetailsList}>
                  <p className={classes.equipSubHead}>Installer:</p>
                  <p className={classes.equipSubHeadDetail}>{Installer}</p>
                </div>
              </div>
            </div>
            <div className={classes.locationDetails}>
              <div className={classes.locationDetailList}>
                <p className={classes.equipSubHead}>
                  Address of the Equipment:
                </p>
                <p className={classes.equipSubHeadDetail}>{addressStr}</p>
              </div>
              <div className={classes.SerailsNumber}>
                <div className={classes.locationDetailList}>
                  <p className={classes.equipSubHead}>Email:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {endUserDetails.email || userSFData.email || 'N/A'}
                  </p>
                  {/* <p className={classes.hoverText}>
                    <p className={classes.rotaryTitle}>
                      {locationDetails?.email}
                    </p>
                    <span className={classes.tooltip}>
                      {locationDetails?.email}
                    </span>
                  </p> */}
                </div>
                <div className={classes.locationDetailList}>
                  <p className={classes.equipSubHead}>Phone:</p>
                  <p className={classes.equipSubHeadDetail}>
                    {locationDetails?.phoneNumber || 'N/A'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <>
          <div>
            <Modal
              open={
                data.isTexaProduct === true &&
                data.isTexaProductActivated === false &&
                texaDetails?.data?.response !== 1 &&
                cancelPopup === false
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.ModalStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.modalTitle}
                >
                  Equipment is Inactive
                </Typography>
                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                  <img src={texaIcon} alt="TexaIcon" />
                </div>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  // className={classes.paraGraph}
                  className={classes.texaInactiveText}
                >
                  <div>
                    Your equipment is registered but not activated. Please
                    activate it.
                  </div>
                </Typography>

                <div className={classes.popupActiveButtons}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setCancelPopup(true)}
                    className={classes.cancelButton}
                  >
                    Activate Later
                  </Button>
                  <Button
                    className={classes.popupActiveButton}
                    type="submit"
                    onClick={() => {
                      handleTexaActivate();
                    }}
                  >
                    Activate Now
                  </Button>
                </div>
              </Box>
            </Modal>
          </div>
          <div>
            <Modal
              open={
                activatedPopup === true && texaDetails?.data?.response !== 1
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.ModalStyle}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.modalTitle}
                >
                  Activation Code
                  <Link onClick={handleClosed} className={classes.closeIcon}>
                    <img src={closeIcon} alt="closeIcon" />
                  </Link>
                </Typography>

                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className={classes.paraGraphcont}
                >
                  <div className={classes.texaInactiveText}>
                    <div>
                      <img src={touchIcon} alt="Touch Icon" />
                    </div>
                    <div className={classes.texaActivatedText}>
                      <p>
                        Your Equipment has been activated from our end, Please
                        enter the activation code below during equipment set up
                      </p>
                    </div>
                    <div className={classes.texaActivatedCode}>
                      {activedCode}
                    </div>
                    <span className={classes.texaActivatedCodeSpan}>
                      An email has also been sent with the activation code to
                      your registered email
                    </span>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </>
      }
    </div>
  );
}

export default RegSuccess;
