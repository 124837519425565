import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import classes from './ActivePopup.module.scss';
import { Button } from '@Components/Button';
import { useNavigate } from 'react-router-dom';
import { authStore } from '@Store/authStore';

const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    height: '200px',
  },
  '@media (min-width: 768px) and (max-width: 915px) and (orientation:landscape)':
    {
      top: '55%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '284px',
    },
  '@media (min-width: 501px) and (max-width: 767px) ': {
    top: '57%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '450px',
    height: '284px',
  },
  '@media (max-width: 500px)': {
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '350px',
    marginTop: '9%',
    height: '284px',
  },
};

const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  fontSize: '18px',
  padding: '10px',
  textAlign: 'center',
};
const paraGraph = {
  fontSize: '14px',
  padding: '15px',
  paddingBottom: '0',
  overflow: 'hidden',
};

const ActivePopup = ({ open, setOpen }) => {
  const { logout } = authStore();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    logout();
    setTimeout(() => {
      navigate('/user-logoff');
    }, 300);
  };
  return (
    <div className={classes.RegisterEquipt_Modal}>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={modalTitle}
            className={classes.titleInfo}
          >
            Inactive User
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={paraGraph}
          >
            <div style={{ textAlign: 'center' }}>
              Please click on close button to redirect to landing page
            </div>
            <div className={classes.verifyButton}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default React.memo(ActivePopup);
