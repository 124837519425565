/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import CreateNewPassword from 'features/authentication/CreateNewPassword';
import ForgetPassword from 'features/authentication/ForgotPassword';
import Register from 'features/authentication/Register';
import ThankYouEmail from 'features/authentication/ThankYouEmail';
import { useRoutes, Outlet } from 'react-router-dom';
import { Login } from './features/authentication';
import Dashboard from './features/dahboard';
import NotFound from './core/NotFound';
import AuthLayout from 'layouts/AuthLayout';
import MasterLayout from 'layouts/MasterLayout';
import Installation from 'features/Installation';
import RegisterEquipLogin from 'features/authentication/RegisterEquipLogin';
import { authService } from '@Services/auth';
import UserLogoff from 'features/authentication/UserLogout';
import getUserAccount from 'utils/getUserAccount';
import { dashBoardService } from '@Services/dahboard';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { element } from 'prop-types';

const Routes = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: '/',
        exact: true,
        element: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress />
            <Login />,
          </Box>
        ),
      },
      {
        path: '/',
        exact: true,
        // element: <RegisterEquipLogin />,
        element: <Login />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/activate',
        element: <ThankYouEmail />,
      },
      {
        path: '/forgot-password',
        element: <ForgetPassword />,
      },
      {
        path: '/create-password',
        element: <CreateNewPassword />,
      },
      {
        path: '/thankyou',
        element: <ThankYouEmail />,
      },
      {
        path: '/apple-app-site-association',
        // exact: true,
        // element: <RegisterEquipLogin />,
      },
      {
        path: '/',
        exact: true,
        element: <Login />,
        // element: <RegisterEquipLogin />,
      },
    ],
  },
];

const PrivateRoutes = [
  {
    element: <MasterLayout />,
    children: [
      {
        path: '/',
        exact: true,
        element: <Dashboard />,
      },
      {
        path: '/dashboard',
        exact: true,
        element: <Dashboard />,
      },
      {
        path: '/VerifySerialNumber',
        exact: true,
        element: (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </Box>
        ),
      },
      {
        path: '/installation',
        exact: true,
        element: <Installation />,
      },

      {
        path: '/installation/:id',
        exact: true,
        element: <Installation />,
      },
    ],
  },
];

export default function Router() {
  const navigate = useNavigate();

  // const isAuthenticated = useIsAuthenticated();

  const { accounts, inProgress, instance } = useMsal();
  const [distNum, setdistNum] = useState({});
  const serNo = React.useRef(null);
  instance.redirectResponse.get('')?.then((res) => {
    serNo.current = res?.state;
  });

  // let userEmailFromAcc;

  if (accounts.length) {
    const [acc] = accounts;
    // userEmailFromAcc = acc.idTokenClaims.emailaddress;
    // setTimeout(() => {
    //   // eslint-disable-next-line react-hooks/rules-of-hooks
    //   // const { instance } = useMsal();
    //   instance
    //     .acquireTokenSilent()
    //     .then((res) => {
    //       console.warn(res, 'res456');
    //     })
    //     .catch((error) => {
    //       console.warn(error, 'error123');
    //     });
    // }, 1000);

    if (acc.idToken) {
      localStorage.setItem('userAccount', JSON.stringify(acc));
    }
  } else {
    localStorage.removeItem('userAccount');
  }

  const userAccount = getUserAccount();

  useEffect(() => {
    if (
      typeof serNo.current === 'string' &&
      serNo.current !== '' &&
      serNo.current !== '[object Object]'
    ) {
      authService.getUserDetails(userAccount?.email).then(({ data }) => {
        setdistNum(data.response);
      });
    }
  }, [userAccount?.email, serNo.current]);

  useEffect(() => {
    if (
      typeof serNo.current === 'string' &&
      serNo.current !== '' &&
      serNo.current !== '[object Object]'
    ) {
      if (distNum.distributorNumber && distNum.userRole === 'Distributor') {
        const userEmail = `${userAccount?.email}`;
        const serNoCurrent = serNo.current;
        const equipment = `${userEmail}/${serNoCurrent}`;
        const isDistributor = `${equipment}?distributorNumber=${distNum?.distributorNumber}`;
        const url =
          distNum?.userRole === 'Distributor' && distNum?.distributorNumber
            ? isDistributor
            : equipment;
        dashBoardService.getProduct(url).then((res) => {
          if (res.response.statusId === 3) {
            setTimeout(() => {
              navigate('/Dashboard');
            }, 1000);

            toast.error('Product cannot be registered at this time', {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            // setTimeout(() => {
            navigate(`/installation/${serNo.current}`);
            // }, 1000);
          }
        });
      } else if (
        distNum?.userRole === 'CSR' ||
        distNum?.userRole === 'Installer' ||
        distNum?.userRole === 'Basic User'
      ) {
        // setTimeout(() => {
        navigate(`/installation/${serNo.current}`);
        // }, 1000);
      } else {
        navigate(`/verifySerialNumber`);
        // setTimeout(() => {
        //   navigate('/Dashboard');
        // });
      }
    }
  }, [distNum, serNo.current]);

  return useRoutes([
    {
      path: '/',
      element: <Outlet />,
      children: !['startup', 'handleRedirect'].includes(inProgress) ? (
        accounts.length ? (
          PrivateRoutes
        ) : (
          Routes
        )
      ) : (
        <></>
      ),
    },

    {
      path: '/postssologin',
      element: <NotFound />,
    },

    {
      path: '/installation/:id',
      exact: true,
      element: <></>,
    },

    // {
    //   path: '*',
    //   // element: <NotFound />,
    //   element:
    //     window.location.pathname === '/user-logoff' ? (
    //       <UserLogoff />
    //     ) : (
    //       <NotFound />
    //     ),
    // },
    {
      path: '*',
      // element: <NotFound />,
      element:
        window.location.pathname === '/user-logoff' ? (
          <UserLogoff />
        ) : window.location.pathname === '/apple-app-site-association' ? (
          ''
        ) : (
          <NotFound />
        ),
    },
  ]);
}
