import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { Header } from '@Components/Header';

import { FooterSection } from '@Components/Footer';

import { useLocation } from 'react-router-dom';

import { DistributorDashboard } from 'features/dahboard/DistributorDashboard';

import CommonLayout from './CommonLayout';

import classes from './MasterLayout.module.scss';

import { authService } from '@Services/auth';

import getUserAccount, { setUserSFDetails } from 'utils/getUserAccount';
import ActivePopup from 'features/dahboard/ActivePopup/ActivePopup';
import { useMsal } from '@azure/msal-react';

const MasterLayout = () => {
  const { pathname } = useLocation();
  const { instance } = useMsal();

  const [isUserDataLoaded, setUserDataLoaded] = useState(false);

  const [showDistrbutorModal, setShowDistrbutorModal] = useState(false);
  const [activeData, setActiveData] = useState(false);
  const [activePopUp, setActivePopUp] = useState(false);
  const navigate = useNavigate();

  // let popupType = 'Distributor';

  const isEndUser = (role) => {
    if (!role) return false;

    return ['Distributor', 'Installer'].includes(role);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks

  useEffect(() => {
    const userAccount = getUserAccount();

    authService
      .getUserDetails(userAccount?.email)
      .then(({ data }) => {
        setUserSFDetails(data.response);

        const user = data.response;

        if (user.userRole === 'Distributor' && !user.distributorNumber) {
          setShowDistrbutorModal(true);
        } else if (user.userRole === 'Installer' && !user.installerNumber) {
          setShowDistrbutorModal(true);
        } else if (
          user.isUserActive === false ||
          user.isExpired === true ||
          user.isSolutionActive === false ||
          user.isStarted === false
        ) {
          setActiveData(true);
          setActivePopUp(true);
        } else {
          setUserDataLoaded(true);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 500 ||
          error.message.toLowerCase() === ''
        ) {
          alert('We are redirecting to landing page, Please login again');
          instance.logoutRedirect({
            idTokenHint: userAccount.idToken,
          });
          // logout();
          localStorage.clear();
          sessionStorage.clear();
          setTimeout(() => {
            navigate('/');
          }, 300);
        }
      });
  }, []);

  const onSucessPopUp = () => {
    setUserDataLoaded(true);

    setShowDistrbutorModal(false);

    const userAccount = getUserAccount();

    authService.getUserDetails(userAccount.email).then(({ data }) => {
      setUserSFDetails(data.response);
    });
  };

  const handlePopup = (value) => {
    setShowDistrbutorModal(value);

    setUserDataLoaded(true);

    setTimeout(() => {
      navigate('/');
    }, 100);
  };
  const handlePopupactive = (value) => {
    setActivePopUp(value);
    // setUserDataLoaded(true);
  };
  const classNameStr =
    (pathname === '/' ? 'dashboard' : pathname.replace('/', '')) + 'Page';

  return (
    <div
      className={classNames(
        classes.dashboardContainer,

        classNames[classNameStr],
      )}
    >
      <div className={classes.mainContainer}>
        <Header />

        <div className={classes.middleContainer}>
          {isUserDataLoaded && <CommonLayout />}

          <div className={classes.RegisterEquipt_Modal}>
            {showDistrbutorModal && (
              <DistributorDashboard
                open={showDistrbutorModal}
                setOpen={handlePopup}
                onSucessPopUp={onSucessPopUp}
              />
            )}
            {activeData === true && (
              <ActivePopup
                open={activePopUp}
                setOpen={handlePopupactive}
                activeData={activeData}
              />
            )}
          </div>
        </div>
      </div>

      <FooterSection />
    </div>
  );
};

export default React.memo(MasterLayout);
