import React from 'react';
import { StyledRadioControl } from '@Components/Radio';
import pick from 'utils/pick';
import classes from './SearchResults.module.scss';

const RenderRow = ({ data, keys }) => {
  // const pickValues = pick(data, ...keys);
  // const labelStr = Object.values(pickValues).join(', ');
  const pickValues = keys.length === 1 ? data.email : pick(data, ...keys);

  const labelStr =
    keys.length === 1 ? data.email : Object.values(pickValues).join(', ');
  return (
    <div key={data?.id} className={classes.searchResults}>
      <StyledRadioControl value={data?.id} label={labelStr} />
    </div>
  );
};

export default React.memo(RenderRow);
