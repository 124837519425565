import { useQuery, useMutation } from '@tanstack/react-query';
import { dashBoardService } from '@Services/dahboard';
import { toast } from 'react-toastify';
import getUserAccount, {
  getUserSFDetails,
  setUserSFDetails,
} from 'utils/getUserAccount';
import { authService } from '@Services/auth';

const queryOptions = {
  keepPreviousData: true,
  staleTime: 10000,
};
export const useProductStatus = (equipmentNumber, enabled = false) => {
  return useQuery({
    queryKey: ['product', equipmentNumber],
    queryFn: async () => {
      const { response } = await dashBoardService.getProductStatus(
        equipmentNumber,
      );
      return response;
    },
    onError: ({ response }) => {
      toast.error(
        response.data.message + `, Please enter vaild serial number`,
        {
          position: 'bottom-center',
          style: { width: '400px', fontSize: '13px', padding: '5px' },
        },
      );
    },
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
    ...queryOptions,
  });
};
export const useProducts = (
  pageNumber,
  pageSize,
  searchText = '',
  pageValue,
) => {
  const userAccount = getUserAccount();
  return useQuery({
    // Add activePage as a dependency
    queryKey: ['products', pageNumber, pageSize, searchText, pageValue],
    queryFn: async () => {
      let userSFData = getUserSFDetails();

      if (!userSFData) {
        const {
          data: { response: userData },
        } = await authService.getUserDetails(userAccount.email);
        userSFData = userData;
        setUserSFDetails(userData);
      }
      const isEndUser = userSFData.userRole === 'Basic User' ? true : false;
      const payload = `${userSFData.id}/${pageNumber}/${pageSize}?isEndUser=${isEndUser}`;
      const payloadSearch = `${userSFData.id}/${pageValue}/${pageSize}?searchValue=${searchText}&isEndUser=${isEndUser}`;
      const url = searchText ? payloadSearch : payload;
      const { data } = await dashBoardService.getProducts(url);
      return data?.response;
    },

    ...queryOptions,
  });
};

export const useProduct = (
  email,
  equipmentNumber,
  enabled = false,
  emailnotify = false,
) => {
  return useQuery({
    queryKey: ['product', email, equipmentNumber, emailnotify],
    queryFn: async () => {
      let userSFData = getUserSFDetails();
      console.warn(userSFData, 'userSFData123');

      const equipment = `${userSFData.email}/${equipmentNumber}?triggerEmail=${emailnotify}`;

      const isDistributor = `${userSFData.email}/${equipmentNumber}?distributorNumber=${userSFData.distributorNumber}&?triggerEmail=${emailnotify}`;
      const url =
        userSFData.userRole === 'Distributor' && userSFData.distributorNumber
          ? isDistributor
          : equipment;
      const { response } = await dashBoardService.getProduct(url);
      return response;
    },
    onError: ({ response }) => {
      toast.error(
        response.data.message + `, Please enter vaild serial number`,
        {
          position: 'bottom-center',
          style: { width: '400px', fontSize: '13px', padding: '5px' },
        },
      );
    },
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000,
    ...queryOptions,
  });
};

const useMutationProduct = () => {
  return useMutation(
    async (payload) => {
      let userSFData = getUserSFDetails();
      console.warn(userSFData, 'userSFData456');
      const equipment = `${userSFData.email}/${payload.serialNumber}?triggerEmail=${payload.emailnotify}`;
      const isDistributor = `${userSFData.email}/${payload.serialNumber}?distributorNumber=${userSFData.distributorNumber}&triggerEmail=${payload.emailnotify}`;
      const url =
        userSFData.userRole === 'Distributor' && userSFData.distributorNumber
          ? isDistributor
          : equipment;
      const res = await dashBoardService.getProduct(url);
      return res.response;
    },
    {
      onError: ({ response }) => {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
          style: { width: '400px', fontSize: '13px', padding: '5px' },
        });
      },
    },
  );
};

const useMutationrequestTransfer = () => {
  return useMutation(dashBoardService.requestForTransfer, {
    onSuccess: ({ data }) => {
      toast.success(data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    onError: ({ response }) => {
      toast.error(response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });
};

export const dashboardQuery = {
  useProductStatus,
  useProducts,
  useProduct,
  useMutationProduct,
  useMutationrequestTransfer,
};
