import React, { useState } from 'react';
import InputField from './Input';
import showIcon from '../../../assets/showIcon.png';
import eyeoff from '../../../assets/eye-off.svg';
import classes from './Input.module.scss';

function InputPassword(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeShow, setEyeShown] = useState(eyeoff);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    eyeShow === eyeoff ? setEyeShown(showIcon) : setEyeShown(eyeoff);
  };

  return (
    <div>
      <InputField {...props} type={passwordShown ? 'text' : 'password'} />

      <button
        className={classes.showPwdICon}
        type="button"
        onClick={togglePassword}
      >
        <img src={eyeShow} alt="ShowPasswordIcon" />
      </button>
    </div>
  );
}

export default InputPassword;
