/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@Components/Button';
import { InputField } from '@Components/Input';
import classes from './RegisterEq.module.scss';
import { dashboardQuery } from '../dashboard.query';
import closeIcon from '../../../assets/closeIcon.svg';
import qrCodeIcon from '../../../assets/dashboardICons/qr-code.svg';
import { toast } from 'react-toastify';
import useQRCodeScan from 'hooks/useQR';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import IconArtwork from '../../../assets/dashboardICons/Icon Artwork.svg';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import { boolean } from 'yup';
const style = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  // Adding media query..
  '@media (min-width: 768px)': {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '667px',
    height: '730px',
  },

  '@media (orientation: landscape)': {
    // backgroundColor: '#000',
  },

  '@media (min-width:769px) and (min-width:900px)': {
    width: '880px',
    height: '500px',
  },
  '@media (min-width:900px)': {
    width: '910px',
    height: '439px',
  },
};
const modalTitle = {
  backgroundColor: '#000',
  color: '#fff',
  padding: '5px 20px 5px 20px ',
  display: 'flex',
  justifyContent: 'space-between',
};
const paraGraph = {
  fontSize: '14px',
  padding: '10px',
};

const RegisterEq = ({ open, setOpen }) => {
  const user = getUserSFDetails();
  const { startQrCode, decodedQRData, stopQrCode, uploadQrimage } =
    useQRCodeScan({
      qrcodeMountNodeID: 'qrcodemountnode',
    });
  const navigate = useNavigate();
  const { setSpinner, isActive } = spinnerStore();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [payload, setPayload] = useState('');
  const [check, setCheck] = useState(false);
  const [recheck, setReCheck] = useState([]);
  const [count, setCount] = useState(0);
  const { mutate, status, data, isFetching } =
    dashboardQuery.useMutationProduct();
  // const { data, status, isSuccess } = dashboardQuery.useProduct(
  //   user.email,
  //   payload,
  //   check,
  // );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const alphaNumicRegx = /\/\/(.+)$/;
  const [finalData, setFinalData] = useState('');
  const [manageState, setManageState] = useState({
    value: '',
    isError: false,
    isDecodeed: false,
  });
  const [alertMessageFlag, setAlertMessageFlag] = useState(true);

  const handleSearch = () => {
    setAlertMessageFlag(false);
    setPayload(serialNumber);
    let emailnotify = false;
    const indexMatch = recheck.findIndex((item) => {
      return serialNumber.toLowerCase() === item.serialNumber.toLowerCase();
    });
    if (indexMatch >= 0) {
      const matchedItem = recheck[indexMatch];
      emailnotify = matchedItem.tries === 1;
    } else {
    }
    setTimeout(() => {
      mutate({ serialNumber, emailnotify });
    }, 300);
  };
  const startQrScanS = () => {
    setOpenPopUp(true);
    setTimeout(() => {
      startQrCode();
    }, 300);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    () => {
      stopQrCode();
    };
  }, []);

  useEffect(() => {
    const { data } = decodedQRData;
    const url = data;
    const urlParams = new URLSearchParams(url);
    const paramValue = urlParams.get('snum');
    const serialNumberInfo = paramValue ? paramValue : data;
    const isValidData = alphaNumicRegx.test(serialNumberInfo || '');
    const serialNo = !isValidData ? serialNumberInfo : '';

    setManageState({
      ...manageState,
      value: serialNo,
      isError: data ? isValidData : false,
      isDecodeed: !!serialNo,
    });
    setFinalData(serialNo);
    if (serialNo) {
      setSerialNumber(serialNo);
      let serialNumber = serialNo; // mutate(payload);
      let emailnotify = false;
      const indexMatch = recheck.findIndex((item) => {
        return serialNumber.toLowerCase() === item.serialNumber.toLowerCase();
      });
      if (indexMatch >= 0) {
        const matchedItem = recheck[indexMatch];
        emailnotify = matchedItem.tries === 1;
      } else {
      }
      setTimeout(() => {
        mutate({ serialNumber, emailnotify });
      }, 300);
      // setPayload(serialNo);
    }
    if (data && openPopUp) {
      setTimeout(() => {
        setOpenPopUp(false);
      }, 300);
    }
  }, [decodedQRData]);

  useEffect(() => {
    if (data && status === 'success') {
      if (data.statusId === 2) {
        setTimeout(() => {
          //TODO - this is hard coded
          navigate('/installation/' + data.equipmentNumber);
        }, 1000);
      } else {
        if (data.statusId === 1 && user.userRole === 'CSR') {
          setTimeout(() => {
            //TODO - this is hard coded
            navigate('/installation/' + data.equipmentNumber);
          }, 100);
        }
        if (data.statusId === 1) {
          toast.error('Product Already Registered!!', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
        if (data.statusId === 3) {
          toast.error('Product cannot be registered at this time', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    }

    if (data === null && status === 'success') {
      const indexMatch = recheck.findIndex((item) => {
        return serialNumber.toLowerCase() === item.serialNumber.toLowerCase();
      });
      if (indexMatch >= 0) {
        const matchedItem = recheck[indexMatch];
        matchedItem.tries = matchedItem.tries + 1;
        // setCount(count + 1);
        if (matchedItem.tries === 2) {
          toast.error(
            'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number. ',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
        } else if (matchedItem.tries > 2) {
          toast.error(
            'The technical support group at technicalsupport@vsgdover.com has been notified about an issue with the entered serial number. ',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
        } else {
          toast.error(
            'Equipment wasn’t found. Please manually enter the serial number.',
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
        }
      } else {
        const serialNumStore = { serialNumber: serialNumber, tries: 1 };
        setReCheck((oldData) => {
          return [...oldData, serialNumStore];
        });
        toast.error(
          'Equipment wasn’t found. Please manually enter the serial number.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
      }

      setSerialNumber('');
    }
  }, [data, status]);

  localStorage.setItem('distributorId', JSON.stringify(data?.distributorId));
  localStorage.setItem('statusId', JSON.stringify(data?.statusId));

  const handleupload = (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore
      return;
    }
    setOpenPopUp(true);
    const imageFile = e.target.files[0];
    setTimeout(() => {
      uploadQrimage(imageFile);
      e.target.value = '';
    }, 300);
  };

  const handleClosePop = () => setOpen(false);

  const handleClose = () => {
    setOpenPopUp(false);
    stopQrCode();
  };

  const handleChange = (val) => {
    setSerialNumber(val.target.value);
  };

  const isInputValid = () => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(serialNumber);
  };
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClosePop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.RegisterEquipt_Modal}>
          <p style={modalTitle}>
            <p className={classes.alignItems}>
              <p>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={classes.textFontSize}
                >
                  New Equipment Registration
                </Typography>
              </p>
              <p className={classes.subTitle}>
                (Please provide the Serial Number or Scan / Upload QR / Bar Code
                to register the new equipment){' '}
              </p>
            </p>
            <p className={classes.iconStyles}>
              <Link onClick={handleClosePop} className={classes.closeIcon}>
                <img src={closeIcon} alt="closeIcon" />
              </Link>
            </p>
          </p>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component="div"
            style={paraGraph}
          >
            <div className={classes.registerNewEquipPopup}>
              <div className={classes.leftContainer}>
                <p>QR / Bar Code Scanner</p>
                <div
                  className={classes.scannerImg}
                  onClick={startQrScanS}
                  role="button"
                >
                  <img src={qrCodeIcon} alt="QR-Code" />
                  <p>
                    <Link>Scan QR / Bar Code</Link>
                  </p>
                </div>
                <p className={classes.orText}>(OR)</p>
                <Button variant="outlined" className={classes.buttonStyles}>
                  <label name="qr-input-file" className={classes.labelStyles}>
                    Upload QR / Bar Code Image
                    <input
                      type="file"
                      id="qr-input-file"
                      accept="image/*"
                      onChange={handleupload}
                      // capture="filesystem"
                      className={classes.fileUploadButton}
                    ></input>
                  </label>
                </Button>
                <Dialog
                  open={openPopUp}
                  maxWidth={!fullScreen ? 'sm' : ''}
                  fullWidth={true}
                  fullScreen={fullScreen}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    className={classes.scanQRCode}
                  >
                    {'Scan Qr Code'}
                  </DialogTitle>
                  <DialogContent
                    sx={{ minHeight: '250px' }}
                    className={classes.dialogContent}
                  >
                    <div
                      id="qrcodemountnode"
                      style={{ width: '330px', height: '330px' }}
                    ></div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                      className={classes.scanButton}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <p className={classes.hoverText}>
                  <img src={IconArtwork} alt="IconArtwork" />
                  <span className={classes.tooltip}>
                    Serial number and QR / Bar code is located on the product
                    information tag, normally located near the power unit,
                    control area or side of the equipment.
                  </span>
                  Hover to view instructions of how to Scan / Upload QR / Bar
                  Code
                </p>

                {/* *Poojitha
                    Below code is used for redirection alert message
                 */}
                {data?.statusId === 2 && decodedQRData.data && (
                  <p className={classes.notifyMessageStyles}>
                    Redirecting, please wait..
                  </p>
                )}
              </div>
              <div className={classes.rightContainer}>
                <div>
                  <div className={classes.userNameCnt}>
                    <InputField
                      label="Search Serial Number"
                      type="text"
                      name="serialNumber"
                      onChange={(val) => {
                        handleChange(val);
                      }}
                      placeholder="Enter valid serial number"
                      value={serialNumber.trim()}
                      className={!isInputValid() && classes.fieldRow}
                    />
                  </div>
                  {!isInputValid() && (
                    <div
                      style={{
                        color: '#c51e1e ',
                        marginTop: '-15px',
                        fontSize: '12px',
                      }}
                    >
                      Special Characters are not allowed!
                    </div>
                  )}
                  <div className={classes.searchButton}>
                    <Button
                      type="submit"
                      onClick={handleSearch}
                      disabled={
                        serialNumber.length <= 3 ||
                        // isFetching ||
                        !isInputValid()
                      }
                    >
                      SEARCH
                    </Button>
                  </div>
                  {!decodedQRData.data && data?.statusId === 2 && (
                    <p className={classes.notifyMessageStyles}>
                      Redirecting, please wait..
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(RegisterEq);
