import { create } from 'zustand';

export const spinnerStore = create((set, get) => ({
  isActive: null,
  showSpinner: () => {
    set({ isActive: true });
  },
  hideSpinner: () => {
    setTimeout(() => {
      set({ isActive: false });
    }, 300);
  },
  setSpinner: (val) => {
    set({ isActive: val });
  },
}));
