/* eslint-disable no-console */
import { useRef, useEffect } from 'react';
import classes from './styles.module.scss';
const AutoComplete = ({ onPlaceChange }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: {
      country: ['US', 'CA'],
    },
    types: ['address'],
  };

  function getAddressInfo(address) {
    let addressComponents = address.address_components;
    let address1 = '';
    let address2 = '';
    let city = '';
    let state = '';
    let country = '';
    let zipCode = '';

    for (const addressComponent of addressComponents) {
      const type = addressComponent.types[0];
      const value = addressComponent.long_name;

      switch (type) {
        case 'street_address':
          address1 = value;
          break;
        case 'street_number':
          address1 = address1 ? address1 : value;
          break;
        case 'route':
          address1 += ' ' + value;
          break;
        case 'sublocality_level_1':
          address2 = value;
          break;
        case 'locality':
          city = value;
          break;
        case 'administrative_area_level_1':
          state = addressComponent.short_name;
          break;
        case 'country':
          country = addressComponent.short_name;
          break;
        case 'postal_code':
          zipCode = value;
          break;
        default:
          break;
      }
    }

    const addressObj = {
      address: {
        address1: address1.trim(),
        address2,
        city,
        state,
        country,
        zip: zipCode,
      },
      selectedAddress: address.formatted_address,
    };

    onPlaceChange(addressObj);
  }

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options,
    );

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      getAddressInfo(place);
    });
  }, []);

  return (
    <div>
      <div className={classes.labelStyles}>
        Search Location <span className={classes.spanColor}>*</span>
      </div>

      <div className={classes.inputWidth}>
        <input ref={inputRef} />
      </div>

      <p className={classes.orText}>
        <span className={classes.orLine}></span>

        <span className={classes.orSpan}>OR</span>

        <span className={classes.orLine}></span>
      </p>
    </div>
  );
};
export default AutoComplete;
