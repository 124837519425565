import React, { useContext } from 'react';
import ButtonRefsContext from './NavigationButtons.context';

function useClickHandlers(handleNextClick, handlePrevClick = () => {}) {
  const { nextButtonRef, prevButtonRef } = useContext(ButtonRefsContext);
  React.useEffect(() => {
    const nextButton = nextButtonRef.current;
    const prevButton = prevButtonRef.current;

    if (nextButton) {
      nextButton.addEventListener('click', handleNextClick);
    }

    if (prevButton) {
      prevButton.addEventListener('click', handlePrevClick);
    }

    return () => {
      if (nextButton) {
        nextButton.removeEventListener('click', handleNextClick);
      }
      if (prevButton) {
        prevButton.removeEventListener('click', handlePrevClick);
      }
    };
  }, [nextButtonRef, prevButtonRef, handleNextClick, handlePrevClick]);
}

export default useClickHandlers;
